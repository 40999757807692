import { Directive, Input, ViewContainerRef, OnInit, AfterViewInit, OnChanges } from '@angular/core';
import { UserService } from '../services/user.service';
import { permission } from '@app/app.config';
import { ToolsService } from '../services/tools.service';

@Directive({
	selector: '[discardView]',
})
export class DiscardViewDirective {
	@Input() discardView: permission[];
	constructor(private viewContainer: ViewContainerRef, private tools: ToolsService, private user: UserService) {}

	ngOnInit() {
		const _permissions = this.user.userInfo.permissions[this.tools.routerConfig.dbColl];
		if (this.discardView.every((item) => _permissions.includes(item))) this.viewContainer.element.nativeElement.remove();
	}
}

// tslint:disable-next-line: max-classes-per-file
@Directive({
	selector: '[attachView]',
})
export class AttachViewDirective implements OnInit {
	@Input() attachView: permission[];
	constructor(private viewContainer: ViewContainerRef, private tools: ToolsService, private user: UserService) {}

	ngOnInit() {
		const _permissions = this.user.userInfo.permissions[this.tools.routerConfig.dbColl];
		if (!this.attachView.every((item) => _permissions.includes(item) || _permissions.includes(permission.all))) {
			this.viewContainer.element.nativeElement.remove();
		}
	}
}

// tslint:disable-next-line: max-classes-per-file
@Directive({
	selector: '[disableView]',
})
export class DisableViewDirective {
	@Input() disableView: permission[];
	constructor(private viewContainer: ViewContainerRef, private tools: ToolsService, private user: UserService) {}

	ngOnInit() {
		const _permissions = this.user.userInfo.permissions[this.tools.routerConfig.dbColl];
		if (this.disableView.every((item) => _permissions.includes(item))) {
			this.viewContainer.element.nativeElement.classList.add('disabled');
			this.viewContainer.element.nativeElement.setAttribute('editable', '');
		}
	}
}
