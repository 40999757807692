import { Component, OnInit } from '@angular/core';
import { FormService } from '@app/shared/services/forms.service';
import { FormBuilder, FormArray } from '@angular/forms';
import { _typify, optionTypesList } from '@app/app.config';
import { ToastService } from '@app/shared/services/toast.service';

@Component({
	selector: 'content-options',
	templateUrl: './content-options.component.html',
	providers: [FormService],
})
export class ContentOptionsComponent implements OnInit {
	optionTypes = optionTypesList;
	showOptions: false;

	private _options: [];
	// '_id' is an input type !

	constructor(public fb: FormBuilder, public fs: FormService, public message: ToastService) {
		fs.init('editor');

		fs.getField('options').valueChanges.subscribe((_) => {
			this._options = _;
		});
	}

	resetOptionValue(idx) {
		_typify<FormArray>(this.fs.getField('options')).at(idx).get('value').reset('');
	}

	formatKeyValue(e, idx) {
		const _key = e.value
			.trim()
			.replace(/\W/g, ' ')
			.replace(/\s(\s+)?/g, '_');
		if (this.fs.getFieldValue('options').some((el, _idx) => el.key == _key && idx != _idx)) {
			_typify<FormArray>(this.fs.getField('options')).at(idx).get('key').patchValue('');
			this.message.set(
				'Form Validation',
				'Option key should be unique (key `' + _key + '` is already used)',
				'warn',
			);
		} else _typify<FormArray>(this.fs.getField('options')).at(idx).get('key').patchValue(_key);
	}

	addOption() {
		_typify<FormArray>(this.fs.getField('options')).push(
			this.fb.group({ key: null, type: this.optionTypes.find((_) => _._id == 'text'), value: null }),
		);
	}

	deleteOption(_deleteOptionAtIdx: number) {
		_typify<FormArray>(this.fs.getField('options')).removeAt(_deleteOptionAtIdx);
	}

	ngOnInit(): void {}
}
