import { Component, OnDestroy, OnInit } from '@angular/core';
import { allLanguages, editorState, permission, projects, userRoles } from '@app/app.config';
import { FormFieldVisibleDirective } from '@app/shared/directives/editor-field-visible.directive';
import { ClientService } from '@app/shared/services/client.service';
import { FormService } from '@app/shared/services/forms.service';
import { EToastReason, ToastService } from '@app/shared/services/toast.service';
import { ToolsService } from '@app/shared/services/tools.service';
import { UserService } from '@app/shared/services/user.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { TreeDragDropService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { merge } from 'rxjs';
import { ContentFinderComponent } from './content-finder/content-finder.component';

@Component({
	selector: 'app-edit-form',
	templateUrl: './edit-form.component.html',
	providers: [FormService, FormFieldVisibleDirective, DialogService, TreeDragDropService],
})
export class EditFormComponent implements OnInit, OnDestroy {
	_bulkMode: boolean;
	_revertedPermEnum = this.tools.reverseEnum(permission);
	_permEnum = Object.values(permission);
	userRoles = userRoles;
	_permissions = [];
	allLanguages;
	editorMenu;

	lokaliseProject = [];
	lokaliseWebhooks = [];
	lokaliseLanguage = null;
	lokalisePairLanguages = [];
	lokaliseInitialValuesProjectsList = null;

	constructor(
		public fs: FormService,
		public tools: ToolsService,
		public toast: ToastService,
		public client: ClientService,
		public user: UserService,
		public _dialog: DialogService,
	) {
		fs.init('editor');

		this.lokaliseLanguage = fs.initialValues?.jurisdictions?.reduce(
			(acc, el) => Object.assign({}, acc, { [el._id]: ['en'] }),
			{},
		);

		tools.editorState.pipe(untilDestroyed(this)).subscribe((_) => {
			switch (_) {
				case editorState.add:
					this.updatePermissionsView('editor', {});
					break;
			}
		});

		merge(tools.formsValue('editor'), tools.apiStates('editorItem'))
			.pipe(untilDestroyed(this))
			.subscribe((_items: any) => {
				if (!_items) return;
				if (_items.permissions) this.updatePermissionsView('data', _items.permissions);

				this.generateLokaliseData();
			});

		tools
			.formsValue('list')
			.pipe(untilDestroyed(this))
			.subscribe((_items) => {
				if (!_items) return;
				this._bulkMode = _items.isBulk;
			});

		// Delete file after decision confirmation
		toast.assuranceConfirmed$.subscribe((_) => {
			if (_.confirmed && _.reason) {
				switch (_.reason) {
					case EToastReason.deleteKey:
						this.deleteListSelectedItems();
						break;
					case EToastReason.syncToLokalise:
						this.syncTranslationsToLokalise();
						break;
					case EToastReason.saveAndSyncToLokalise:
						this.onSave(true);
						break;
				}
			}
		});
	}

	private deleteListSelectedItems() {
		this.fs._provider.clearFormControls('list');
		this.delete();
	}

	ngOnInit() {
		this._initConstants();

		// this.fs.form
		// 	.get('lokaliseToken')
		// 	.valueChanges.pipe(
		// 		debounceTime(300),
		// 		filter((val) => val && val != this.tools.lokaliseContextState.value?.formValues?.lokaliseToken),
		// 	)
		// 	.subscribe((val) => {
		// 		// this.client.checkLokaliseToken(val); TODO the check should happened just once and only on typing not on saving
		// 	});
	}

	updatePermissionsView(_state: 'data' | 'editor', _data) {
		switch (_state) {
			case 'data':
				{
					this._permissions = this.user.securedRoutesDBCollsList.reduce((acc, key) => {
						const _permissionPerColl = _data[key]
							? _data[key].reduce((collAcc, el) => {
									collAcc.push(this._revertedPermEnum[el]);
									return collAcc;
							  }, [])
							: [];

						acc.push({ label: key, value: _permissionPerColl });
						return acc;
					}, []);
				}
				break;
			case 'editor':
				{
					this._permissions = this.user.securedRoutesDBCollsList.map((_) => ({ label: _, value: [] }));
				}
				break;
		}
	}

	removePageElem(e: Event, pageId: string) {
		e.stopPropagation();
		this.fs
			.getField('pages')
			.patchValue(this.fs.getFieldValue('pages').filter((page) => page._id !== pageId));
	}

	formatKeyValue(e) {
		this.fs.getField('key').patchValue(
			e.value
				.trim()
				.toLowerCase()
				.replace(/\W/g, ' ')
				.replace(/\s(\s+)?/g, '_'),
		);
	}

	toggleSearchContent(e) {
		if (e.checked === true) {
			const ref = this._dialog.open(ContentFinderComponent, {
				header: 'Search for source document',
				width: '700px',
				contentStyle: { 'min-height': '450px', 'max-height': '650px', overflow: 'auto' },
			});

			ref.onClose.subscribe((_) => {
				if (!_) this.fs.getField('useContentFrom').reset(null);
				else this.fs.getField('useContentFrom').patchValue(_);
			});
		}
	}

	filterPageSelector(filterKey) {
		const _pages = this.fs.initialValues.pages;
		let _res;
		switch (filterKey) {
			case 'portal':
				_res = _pages.filter((page) => page._project.name.endsWith(projects.portal));
				break;
			case 'main':
				_res = _pages.filter((page) => page._project.name.endsWith(projects.main));
				break;
			case 'lp':
				_res = _pages.filter((page) => page._project.name.endsWith(projects.lp));
				break;
			case 'custom':
				_res = [];
				break;
			case 'all':
				_res = _pages;
				break;
		}
		this.fs.getField('pages').patchValue(_res);
	}

	cancel() {
		this.tools.setEditorState('wait');
		this.fs.clear();
	}

	// upload values to lokalise
	syncTranslationsToLokalise() {
		this.client.syncFieldsToLokalise([{ _id: this.fs.getFieldValue('id') }], true);
		this.tools.setEditorState('load');
	}

	// two way sync with lokalise (lokalise values has a priority)
	syncTranslationsFromLokalise() {
		this.client.syncWithLokalise([{ _id: this.fs.getFieldValue('id') }], true);
		this.tools.setEditorState('load');
	}

	resetLokalise() {
		this.tools.setEditorState('wait');
		this.client.resetLokalise();
		this.fs.clear();
	}

	updateLokaliseTags() {
		this.client.updateLokaliseTags();
	}

	delete() {
		this.tools.setEditorState('wait');
		this.client.deleteCollectionItem([{ _id: this.fs.getFieldValue('id') }]);
		this.fs.clear();
	}

	reloadList() {
		this.client.loadCollectionItemsList();
	}

	onSave(syncAll = false) {
		this.patchLokaliseLanguages();
		if (this.fs.formValid) {
			const formSubmitValues = this.fs.formSubmitValues;
			if (this.tools.editorStateValue.add) this.client.addNewCollectionItem(formSubmitValues);
			else this.client.updateCollectionItem(formSubmitValues, syncAll);

			this.tools.setEditorState('load');
			// NOTE: this.fs._provider.clearFormControls('list')
		}
	}

	languageSelectChanged(code) {
		this.fs.form.patchValue({
			name: allLanguages[code].name,
			native: allLanguages[code].native,
			rtl: !!allLanguages[code].rtl,
			value: code,
		});
	}

	generateLokaliseData() {
		this.lokaliseInitialValuesProjectsList = this.fs.initialLokaliseValues?.__projectsList?.map((el) => ({
			label: el.name,
			value: el.project_id,
		}));

		const _currentLokaliseProjects = (this.fs.getFieldValue('lokaliseProjects') || []).reduce(
			(acc, el) => Object.assign({}, acc, el),
			{},
		);

		if (_currentLokaliseProjects && Object.keys(_currentLokaliseProjects).length) {
			this.fs.initialValues.jurisdictions.forEach((jdx, idx) => {
				if (_currentLokaliseProjects[jdx._id]) {
					this.lokaliseProject[idx] = _currentLokaliseProjects[jdx._id];
				}
			});
		}

		const _currentLokaliseLanguages = this.fs.getFieldValue('lokaliseLanguages') || this.lokaliseLanguage;

		if (_currentLokaliseLanguages && Object.keys(_currentLokaliseLanguages).length) {
			this.fs.initialValues.jurisdictions.forEach((jdx, idx) => {
				if (_currentLokaliseLanguages[jdx._id]) {
					this.lokaliseLanguage[jdx._id] = _currentLokaliseLanguages[jdx._id];
				}
			});
		}

		const _currentLokaliseWebhooks = (this.fs.getFieldValue('lokaliseWebhooks') || []).reduce(
			(acc, el) => Object.assign({}, acc, el),
			{},
		);

		if (_currentLokaliseWebhooks && Object.keys(_currentLokaliseWebhooks).length) {
			this.fs.initialValues.jurisdictions.forEach((jdx, idx) => {
				if (_currentLokaliseWebhooks[jdx._id]) {
					this.lokaliseWebhooks[idx] = _currentLokaliseWebhooks[jdx._id];
				}
			});
		}
	}

	patchLokaliseProjectsToJDXList() {
		this.fs
			.getField('lokaliseProjects')
			.patchValue(
				this.lokaliseProject.map((id, idx) => ({ [this.fs.initialValues.jurisdictions[idx]._id]: id })),
			);
	}

	patchLokaliseWebhooksToJDXList() {
		this.fs
			.getField('lokaliseWebhooks')
			.patchValue(
				this.lokaliseWebhooks.map((id, idx) => ({ [this.fs.initialValues.jurisdictions[idx]._id]: id })),
			);
	}

	patchLokaliseLanguages() {
		if (this.tools.routerState == 'users')
			this.fs.getField('lokaliseLanguages').patchValue(this.lokaliseLanguage);
	}

	private _initConstants() {
		this.editorMenu = [
			...(this.tools.routerState == 'fields'
				? [
						{
							label: 'Save and Sync All Translations',
							icon: 'pi pi-cloud-upload',
							disabled: !this.tools.isLokaliseAuthorized || !this.tools.lokaliseContextState.value,
							command: () => {
								this.toast.set(
									'This action will save all changes and sync ALL translations to lokalise.',
									'Confirm to proceed',
									'warn',
									'assurance',
									true,
									EToastReason.saveAndSyncToLokalise,
								);
							},
						},
						{
							label: 'Sync To Lokalise',
							icon: 'pi pi-cloud-upload',
							disabled: !this.tools.isLokaliseAuthorized || !this.tools.lokaliseContextState.value,
							command: () => {
								this.toast.set(
									'This action will sync all existing values (if you change something, use `Save and Sync All Translations` instead).',
									'Confirm to proceed',
									'warn',
									'assurance',
									true,
									EToastReason.syncToLokalise,
								);
							},
						},
						{
							label: 'Sync From Lokalise',
							icon: 'pi pi-cloud-download',
							disabled: !this.tools.isLokaliseAuthorized || !this.tools.lokaliseContextState.value,
							command: () => {
								this.syncTranslationsFromLokalise();
							},
						},
				  ]
				: []),
			{
				label: 'Delete',
				icon: 'pi pi-trash',
				disabled: !this.user.toDelete(),
				command: () => {
					// if (this.user.hasRole(userRoles.superadmin) && this.tools.routerState == 'fields') {
					// 	this.deleteListSelectedItems();
					// } else {
					this.toast.set(
						'Are you sure?',
						'Confirm to proceed',
						'warn',
						'assurance',
						true,
						EToastReason.deleteKey,
					);
					// }
				},
			},
			...(this.tools.routerState == 'users'
				? [
						{
							label: 'Reset Lokalise',
							icon: 'pi pi-undo',
							disabled: !this.tools.isLokaliseAuthorized || !this.tools.lokaliseContextState.value,
							command: () => {
								this.fs._provider.clearFormControls('list');
								this.resetLokalise();
							},
						},
						{
							label: 'Sync Lokalise Tags',
							icon: 'pi pi-tags',
							disabled: !this.tools.isLokaliseAuthorized || !this.tools.lokaliseContextState.value,
							command: () => {
								this.updateLokaliseTags();
							},
						},
				  ]
				: []),
			{
				label: 'Cancel',
				icon: 'pi pi-times',
				command: () => {
					this.fs._provider.clearFormControls('list');
					this.cancel();
				},
			},
		];

		this.allLanguages = Object.keys(allLanguages).reduce((acc, key) => {
			acc.push({
				label: allLanguages[key].name,
				value: key,
			});
			return acc;
		}, []);
	}

	ngOnDestroy() {}
}
