import { Directive, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import striptags from 'striptags';
import { fromEvent, timer, concat } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Directive({
	selector: '[striptags]',
})
export class StripTagsDirective implements AfterViewInit, OnDestroy {
	constructor(private control: NgControl, private elem: ElementRef) {}

	ngAfterViewInit() {
		fromEvent(this.elem.nativeElement, 'blur')
			.pipe(untilDestroyed(this))
			.subscribe((_: any) => {
				if (this.control.value && this.control.value.search(/<[^>]*>/g) != -1) {
					this.elem.nativeElement.classList.add('hasHTMLTags');
					timer(3500).subscribe((_) => this.elem.nativeElement.classList.remove('hasHTMLTags'));
				}
				this.control.reset(striptags(this.control.value, [], ' ').replace(/(\s\s+)|(\$nbsp\;)/g, ' '));
			});
	}

	ngOnDestroy() {}
}
