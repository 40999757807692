import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FormService } from '@app/shared/services/forms.service';
import { ToastService } from '@app/shared/services/toast.service';
import * as YouTubePlayer from 'youtube-player';

@Component({
	selector: 'add-video',
	templateUrl: './add-video.component.html',
	providers: [FormService],
})
export class AddVideoComponent implements AfterViewInit {
	private player;
	@ViewChild('YTPlayer') YTPlayer: ElementRef;

	constructor(
		public fs: FormService,
		private ref: DynamicDialogRef,
		private config: DynamicDialogConfig,
		private message: ToastService,
	) {
		fs.init('video');
		fs.getField('order').patchValue(this.config.data.videos.length + 1); // REMOVE ME LATER

		fs.getField('videoID').valueChanges.subscribe((_) => {
			this.player.loadVideoById(_);
		});
	}

	ngAfterViewInit() {
		this.player = YouTubePlayer(
			this.YTPlayer.nativeElement.id,
			{
				width: 260,
				height: 178,
			},
			true,
		);
	}

	onSave() {
		if (this.fs.formValid) {
			this.ref.close(this.fs.form.value);
		}
	}

	onCancel() {
		this.ref.close(null);
	}

	onSelectThumbnail(e) {
		this.fs.form.get('file').patchValue(e.currentFiles[0]);
	}

	onRemoveThumbnail(e) {
		this.fs.form.get('file').patchValue(null);
	}
}
