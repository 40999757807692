import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApiService } from '../services/api.service';
import { _routerOptions } from '@app/app.config';

@Injectable()
export class InfoContextGuard implements Resolve<any> {
	constructor(private apiService: ApiService) {}
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
		try {
			return this.apiService.getLogs();
		} catch (e) {
			console.error(e);
			return null;
		}
	}
}
