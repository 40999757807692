import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToolsService } from '../tools.service';
import { map, throttleTime, distinctUntilChanged, pluck } from 'rxjs/operators';
import * as langs from 'langs';
import { Observable, asyncScheduler } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LanguageService {
	private _langs;

	constructor(private tools: ToolsService, private http: HttpClient) {
		tools.apiStates('_data').subscribe((_) => {
			if (_.languages) {
				const supported_iso639_1: string[] = _.languages.map((lang) => lang.value);
				this._langs = langs.all().filter((el) => supported_iso639_1.includes(el['1']));
			}
		});
	}

	detect(text: string): Observable<string> {
		return this.http.post('/detectlanguage/0.2/detect', { q: text }).pipe(
			pluck('data', 'detections', '0', 'language'),
			map((detected_lang: string) => this._langs.find((lang) => lang['1'] == detected_lang)),
		);
	}
}
