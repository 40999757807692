import { Component, OnInit } from '@angular/core';
import { ToolsService } from '@app/shared/services/tools.service';
import { ClientService } from '@app/shared/services/client.service';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { TitleCasePipe, DatePipe } from '@angular/common';

@Component({
	selector: 'overall-info',
	templateUrl: './overall-info.component.html',
	providers: [TitleCasePipe, DatePipe],
})
export class OverallInfoComponent implements OnInit {
	logs$;
	approvedLogs$;
	selectedLog;
	selectedLogOnApprove;

	constructor(
		private tools: ToolsService,
		private client: ClientService,
		private titleCase: TitleCasePipe,
		private date: DatePipe,
	) {
		this.logs$ = tools.apiStates(['logs', '_data']).pipe(
			map((_: any) => {
				const _data = [];
				_.logs?.forEach((logEl) => {
					const _value = {};
					const _collItem = _._data[logEl.collectionName].find((collElem) => collElem._id == logEl._itemId);
					const _language = logEl._language
						? _._data['languages'].find((collElem) => collElem._id == logEl._language)
						: null;
					const _jurisdiction = logEl._jurisdiction
						? _._data['jurisdictions'].find((collElem) => collElem._id == logEl._jurisdiction)
						: null;
					const _author = _._data['users'].find((collElem) => collElem._id == logEl._author);
					if (!_collItem || !logEl.changedFields) return;
					// Name
					_value['_id'] = logEl._id;
					_value['_uid'] = logEl._itemId + (logEl._language || '') + (logEl._jurisdiction || '');
					_value['name'] = `[ ${titleCase.transform(logEl.collectionName)} ] ${_collItem.name}`;
					_value['tag'] =
						_language && _jurisdiction
							? `${_language.name.slice(0, 2).toLowerCase()}.${_jurisdiction.name.toLowerCase()}`
							: logEl.changedFields.toLowerCase();
					_value['author'] = _author?.name || 'ʕ•̫͡•ʔ♡*:.✧';
					_value['date'] = date.transform(logEl.date, 'dd MMM - HH:MM');
					_value['status'] = logEl.changesType;
					_value['oldvalue'] = logEl.oldData ? Object.values(logEl.oldData)[0] : '-- no value --';
					_value['newvalue'] = logEl.newData ? Object.values(logEl.newData)[0] : '-- no value --';
					_value['collection'] = logEl.collectionName;
					_data.push(_value);
				});
				// console.log(_data)
				return _data;
			}),
		);

		this.approvedLogs$ = this.logs$.pipe(
			// map((_: any) => Object.assign({}, _, { misc: _.misc.filter(el => el.onReview) })),
			map((_data: any) => {
				const _approved = _data
					.filter((el) => ['fields', 'pages'].includes(el.collection))
					.sort((a, b) => a.date - b.date)
					.reduce((acc, el) => {
						return Object.assign({}, acc, { [el._uid]: el });
					}, {});
				// console.log(_misc)

				return Object.values(_approved);
			}),
		);
	}

	showLogsFilter() {}

	approve(item) {
		this.client.approveLog(item);
	}

	ngOnInit(): void {}
}
