import { Directive, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Directive({
	selector: '[stripspaces]',
})
export class StripSpacesDirective implements AfterViewInit, OnDestroy {
	constructor(private control: NgControl, private elem: ElementRef) {}

	ngAfterViewInit() {
		fromEvent(this.elem.nativeElement, 'blur')
			.pipe(untilDestroyed(this))
			.subscribe((_: any) => {
				if (this.control.value) this.control.reset(this.control.value.replace(/(\s\s+)|(\$nbsp\;)/g, ' '));
			});
	}

	ngOnDestroy() {}
}
