import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';
import { map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import * as ls from 'localStorage';
import { IDataResponse, IAuthUserResponse } from '../app.interface';

@Injectable()
export class TokenGuard implements CanActivate {
	constructor(private userService: UserService, private apiService: ApiService, private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const _returnUrl = route.queryParams['returnUrl'],
			_routeState = route.routeConfig.path;

		switch (_routeState) {
			case 'login':
				{
					if (this.userService.authorized) this.router.navigate([_returnUrl || 'editor']);
					if (this.userService.initialized) this.router.navigate([_returnUrl]);
					else return of(true);
				}
				break;
			case 'editor':
				{
					if (this.userService.authorized) {
						if (this.userService.initialized) {
							return of(true);
							// this.router.navigate(['editor/fields']);
						} else {
							return this.apiService.user().pipe(
								map((_user: IDataResponse<IAuthUserResponse>) => {
									if (_user.response && _user.response.user) {
										this.userService.setUserInfo(_user.response.user);
										return true;
									} else {
										ls.clear();
										this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
										return false;
									}
								}),
							);
						}
					} else {
						this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
					}
				}
				break;
		}

		return of(false);
	}
}
