import {
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	QueryList,
	ViewChildren,
} from '@angular/core';
import { FormService } from '@app/shared/services/forms.service';
import { ToolsService } from '@app/shared/services/tools.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { LocalOptionsComponent } from '../content-options/local-options/local-options.component';
import { FormFieldVisibleDirective } from '@app/shared/directives/editor-field-visible.directive';
import * as JSONEditor from 'jsoneditor';
import { AbstractControl } from '@angular/forms';
import { jurisdictions } from '@app/app.config';

JSONEditor.ace.config.set('maxLines', Infinity);
enum JSONEditorSchemaType {
	Object = 'object',
	Other = 'object',
	Array = 'array',
}
@Component({
	selector: 'edit-form-content-builder',
	templateUrl: './content-builder.component.html',
	providers: [FormService, DialogService, FormFieldVisibleDirective],
})
export class EditFormContentBuilderComponent implements OnInit, OnChanges, OnDestroy {
	languages: any[];
	@ViewChildren('jsoneditor') set jsoneditors(els: QueryList<ElementRef>) {
		this.editors = els.toArray().map((el) => {
			const _formControls = this.fs.getField(this._contextField)['controls'];
			const _groupIdx = parseInt(el.nativeElement.getAttribute('groupidx'));
			const _lang = el.nativeElement.getAttribute('fieldlang');
			const _schemaType = el.nativeElement.getAttribute('type');
			const _control: AbstractControl = _formControls[_groupIdx];
			const _controlValue = _control.value.value && JSON.parse(_control.value.value);
			const _initVal = _controlValue || (_schemaType === 'Object' || _schemaType === 'Other' ? {} : []);

			return new JSONEditor(
				el.nativeElement,
				{
					mode: this.tools.isLokaliseAuthorized ? (_lang == 'en' ? 'code' : 'preview') : 'code',
					mainMenuBar: false,
					navigationBar: false,
					statusBar: false,
					schema: {
						type: JSONEditorSchemaType[_schemaType],
						additionalProperties: { type: 'string' },
						items: {
							type: 'string',
						},
					},
					onChangeText: (json) => {
						try {
							const _val = JSON.stringify(JSON.parse(json));
							_control.setValue(Object.assign({}, _control.value, { value: _val }));
						} catch (e) {}
					},
				},
				_initVal,
			);
		});
	}
	@Input() itemId: string;
	jurisdictions = jurisdictions;
	toggleLangListView = false;
	lokaliseLangList = null;
	jdxList;
	jdx = [];
	editors = [];
	private _unsubscribe$ = new Subscription();

	constructor(public fs: FormService, private _dialog: DialogService, public tools: ToolsService) {
		fs.init('editor');
		this.jdxList = this.fs.initialValues?.jurisdictions;

		this._unsubscribe$.add(
			fs
				.getField('useContentFrom')
				.valueChanges.pipe(untilDestroyed(this))
				.subscribe((_) => {
					if (_ && _._id) {
						this.jdx = this.jdxList;
						this.toggleLangListView = false;
						this._updateLokaliseLanguageList();
					}
				}),
		);

		this._unsubscribe$.add(
			fs
				.getField('builderType')
				.valueChanges.pipe(untilDestroyed(this))
				.subscribe((_) => {
					if (_) {
						this.editors.forEach((JsonEditor) =>
							JsonEditor.setSchema({
								type: JSONEditorSchemaType[_.name],
								additionalProperties: { type: 'string' },
								items: {
									type: 'string',
								},
							}),
						);
					}
				}),
		);

		this._unsubscribe$.add(
			this.tools.editorState.subscribe((state) => {
				this.languages =
					state === 'add' ? [this.fs.initialValues?.languages[0]] : fs.initialValues?.languages;
				this.toggleLangListView = state === 'add';
			}),
		);
	}

	private _updateLokaliseLanguageList() {
		const lokaliseLanguages = this.tools.lokaliseContextState.value?.formValues?.lokaliseLanguages;
		if (lokaliseLanguages) {
			this.lokaliseLangList = [
				...this.jdxList
					.reduce((acc, jdx) => {
						acc.push(lokaliseLanguages[jdx._id]);
						return acc;
					}, [])
					.reduce((acc, el) => {
						for (let item of el) acc.add(item);
						return acc;
					}, new Set()),
			];
		}
	}

	get _contextField() {
		return this.fs.showFieldIfFormAllows('meta')
			? 'meta'
			: this.fs.showFieldIfFormAllows('content')
			? 'content'
			: null;
	}

	ngOnInit() {
		const _currentLokaliseProjects = (
			this.tools.lokaliseContextState.value?.formValues?.lokaliseProjects || []
		).reduce((acc, el) => Object.assign({}, acc, el), {});

		if (_currentLokaliseProjects && Object.keys(_currentLokaliseProjects).length) {
			let lokaliseSupportedJdx = Object.keys(_currentLokaliseProjects);
			this.jdxList = this.jdxList.filter((jdx) => lokaliseSupportedJdx.includes(jdx._id));
			this._updateLokaliseLanguageList();
			this.jdx = this.jdxList;
		}
	}

	toggleLocked(idx) {
		const _lockedField = this.fs.form.get(`content.${idx}.locked`);
		_lockedField.setValue(!_lockedField.value);
	}

	toggleLocalOptions(idx) {
		const ref = this._dialog.open(LocalOptionsComponent, {
			header: 'Local Options of the translation',
			width: '700px',
			contentStyle: { 'min-height': '450px', 'max-height': '650px', overflow: 'auto' },
		});

		this._unsubscribe$.add(
			ref.onClose.subscribe((_) => {
				if (!_) this.fs.getField('useContentFrom').reset(null);
				else this.fs.getField('useContentFrom').patchValue(_);
			}),
		);
	}

	toSource() {
		const _val = this.fs.getFieldValue('useContentFrom');
		if (_val && _val._id) {
			this.fs._provider.listForm.get('selectedItem').patchValue(_val);
		}
	}

	unlinkSource() {
		const _val = this.fs.getField('useContentFrom');
		if (_val.value && _val.value._id) _val.reset();
	}

	ngOnChanges() {
		this.jdx = this.jdxList;
		this._updateLokaliseLanguageList();
	}

	ngOnDestroy() {
		this._unsubscribe$.unsubscribe();
		this.editors.forEach((JsonEditor) => JsonEditor.destroy());
	}
}
