import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import Prism from 'prismjs';
import * as Diff from 'diff';
import beautify from 'js-beautify';
import striptags from 'striptags';

@Directive({
	selector: '[diffHighlighter]',
})
export class CodeHighlighterDirective implements OnInit {
	@Input('diffData') private _data: { new: any; old: any };
	@Input('diffComparer') private _comp: 'words' | 'json';
	@Input('difViewRes') private _res: 'old' | 'new';

	constructor(private el: ElementRef) {}

	ngOnInit() {
		let _codeOld = JSON.stringify(this._data.old); //'[{ str: "Hello Bob", num: 43, a: 1, b: 2 }]'
		let _codeNew = JSON.stringify(this._data.new); //'[{ str: "Hello World", a: 2 }]'

		if (_codeOld.search(/<[^>]*>/g) != -1 || _codeNew.search(/<[^>]*>/g) != -1) {
			_codeOld = striptags(_codeOld, [], ' ').replace(/(\s\s+)|(\$nbsp\;)/g, ' ');
			_codeNew = striptags(_codeNew, [], ' ').replace(/(\s\s+)|(\$nbsp\;)/g, ' ');
		}

		const _textNew = beautify(_codeNew, { indent_size: 2, space_in_empty_paren: true });
		const _textOld = beautify(_codeOld, { indent_size: 2, space_in_empty_paren: true });

		const resNew = [];
		const resOld = [];

		let _diff;
		if (this._comp == 'json') {
			_diff = Diff.diffJson(_textOld, _textNew, { newlineIsToken: true });
			_diff
				.filter((el) => el.value != '\n')
				.forEach((el, idx) => {
					if (!el.removed) {
						if (el.added && _diff[idx - 1] && !_diff[idx - 1].removed) {
							resNew.push(`<span class="added">${el.value}</span>`);
							resOld.push(`<span class="neutral">${(el.value.match(/\n/g) || []).join('')}</span>`);
						} else {
							resNew.push(`<span class="line">${el.value}</span>`);
						}
					}

					if (!el.added) {
						if (el.removed && _diff[idx + 1] && !_diff[idx + 1].added) {
							resOld.push(`<span class="removed">${el.value}</span>`);
							resNew.push(`<span class="neutral">${(el.value.match(/\n/g) || []).join('')}</span>`);
						} else {
							resOld.push(`<span class="line">${el.value}</span>`);
						}
					}
				});
		}
		if (this._comp == 'words') {
			_diff = Diff.diffWords(_textOld, _textNew, { newlineIsToken: true });
			_diff
				.filter((el) => el.value != '\n')
				.forEach((el, idx) => {
					if (!el.removed) {
						if (el.added) {
							if (_diff[idx - 1] && !_diff[idx - 1].removed) {
								resOld.push(`<span class="neutral">${new Array(el.value.length).join(' ')}</span>`);
							}
							resNew.push(`<span class="added">${el.value}</span>`);
						} else {
							resNew.push(el.value);
						}
					}

					if (!el.added) {
						if (el.removed) {
							if (_diff[idx + 1] && !_diff[idx + 1].added) {
								resNew.push(`<span class="neutral">${new Array(el.value.length).join(' ')}</span>`);
							}
							resOld.push(`<span class="removed">${el.value}</span>`);
						} else {
							resOld.push(el.value);
						}
					}
				});
		}

		// console.log(_textNew.split('\n'), _textOld.split('\n'))
		// console.log(_diff)
		// console.log(resNew, resOld)

		if (this._res == 'new') this.el.nativeElement.innerHTML = resNew.join('');

		if (this._res == 'old') this.el.nativeElement.innerHTML = resOld.join('');
	}
}
