import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
	DiscardViewDirective,
	AttachViewDirective,
	DisableViewDirective,
} from './directives/user-permission.directive';
import { StripSpacesDirective } from './directives/strip-spacing.directive';
import { StripTagsDirective } from './directives/strip-html.directive';
import { InputPrefixDirective } from './directives/input-prefix.directive';
import { FormFieldVisibleDirective } from './directives/editor-field-visible.directive';
import { ServerLostComponent } from './components/502.component';
import { CodeHighlighterDirective } from './directives/code-highlighter.directive';

@NgModule({
	declarations: [
		DiscardViewDirective,
		StripSpacesDirective,
		StripTagsDirective,
		InputPrefixDirective,
		FormFieldVisibleDirective,
		ServerLostComponent,
		CodeHighlighterDirective,
		AttachViewDirective,
		DisableViewDirective,
	],
	exports: [FormFieldVisibleDirective, CodeHighlighterDirective],
	imports: [CommonModule],
})
export class SharedModule {}
