import { Injectable, OnDestroy, Inject } from '@angular/core';
import {
	_routerOptions,
	formType,
	routeState,
	ROUTE_EVENT,
	apiAction,
	editorState,
	routes,
} from '@app/app.config';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, of, empty, Observable, EMPTY } from 'rxjs';
import { scan, switchMap, distinctUntilChanged, pairwise, filter, map, tap } from 'rxjs/operators';
import { IDataResponse, IFilterResponse } from '../app.interface';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ToastService } from './toast.service';
import { Location } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class ToolsService implements OnDestroy {
	private contentLocked$ = new BehaviorSubject<any>(false);
	private contentLoading$ = new BehaviorSubject<any>(false);
	private contextLoading$ = new BehaviorSubject<any>(false); // Interactive loading to indicate some process states

	private apiState$ = new BehaviorSubject<any>(null);
	private apiStateArchive$ = new BehaviorSubject<any>(null);

	private filterLocked$ = new BehaviorSubject<any>(false);

	private formState$ = new Subject();
	private formsStateArchive$ = new BehaviorSubject<any>({});

	private editorState$ = new BehaviorSubject<any>(editorState.wait);
	private routeContextState$ = new BehaviorSubject<any>(null);
	private lokaliseContextState$ = new BehaviorSubject<any>(null);

	private initRouteSnapshotState(_data) {
		Object.keys(_data).forEach((key) => {
			switch (key) {
				case 'context':
					{
						if (_data[key].response) this.setRouteContextState(_data[key].response.data as IFilterResponse);
					}
					break;
				case 'lokalise':
					{
						if (_data[key].response) {
							this.setLokaliseContextState(_data[key].response.data as IFilterResponse);
						}
					}
					break;
			}
		});
	}

	constructor(
		@Inject(ROUTE_EVENT) routeEventsState: { [k in routeState]: (() => void)[] },
		private router: Router,
		private location: Location,
		private message: ToastService,
	) {
		// Manipulate application states
		this.router.events.subscribe((event: any) => {
			switch (event.constructor.name) {
				case routeState.NavigationStart:
					{
						this.contentLoading$.next(true);
						this.filterLocked$.next(true);
						this.clearApiState('editorItem');
						this.clearApiState('list');
					}
					break;
				case routeState.ActivationEnd:
					{
						this.initRouteSnapshotState(event.snapshot.data);
					}
					break;
				case routeState.NavigationEnd:
					{
						this.filterLocked$.next(false);
						this.contentLoading$.next(false);
					}
					break;
			}

			// Calling callbacks for specific route event from another components
			(routeEventsState[event.constructor.name] || []).forEach((_fn) => _fn());
		});

		this.apiState$
			.pipe(
				untilDestroyed(this),
				tap((val: IDataResponse<any>) => {
					if (val) {
						switch (val.action) {
							case 'lokalise':
								{
									if (val.response) {
										this.setLokaliseContextState(val.response.data as IFilterResponse);
									}
								}
								break;
						}
					}
				}),
				scan((acc: any, curr: IDataResponse<any>) => {
					return Object.assign(
						{},
						acc,
						curr ? { [curr.action]: curr.response ? curr.response.data || curr.response : undefined } : {},
					);
				}, {}),
			)
			.subscribe((_history) => this.apiStateArchive$.next(_history));

		this.formState$
			.pipe(
				untilDestroyed(this),
				scan((acc, curr) => Object.assign({}, acc, curr), {}),
			)
			.subscribe((_) => this.formsStateArchive$.next(_));

		// DEV
		// this.formsStateArchive$.subscribe((_) => console.log('forms', _));
		// this.apiStateArchive$.subscribe((_) => console.log('api', _));
		// this.routeContextState$.subscribe((_) => console.log('context', _));
		// This.editorState$.subscribe(_ => console.log('editor', _))
	}

	get apiState() {
		return this.apiState$;
	}

	// Return the item(s) of ApiHistory object
	apiStates(_filter: keyof typeof apiAction | (keyof typeof apiAction)[], asValue = false) {
		const emptyValPerType = () => {
			switch (_filter) {
				case 'misc':
					return [];
				default:
					return null;
			}
		};

		if (!asValue) {
			return this.apiStateArchive$.pipe(
				untilDestroyed(this),
				distinctUntilChanged((prev, curr) => {
					const _exception = this.routerState == 'login';
					const _filterVal: (keyof typeof apiAction)[] = (Array(_filter) as any).flat();
					return !_exception && _filterVal.some((key) => prev[key] == curr[key]);
				}),
				switchMap((stateHistory) => {
					const perStateDataFilter = (stateKey: apiAction) => {
						switch (stateKey) {
							case apiAction.search: {
								return stateHistory[stateKey];
							}
							default:
								return stateHistory[stateKey] == null || Object.values(stateHistory[stateKey]).length != 0;
						}
					};

					const elements = Object.keys(stateHistory).reduce((acc, key: apiAction) => {
						const _val =
							_filter.toString().includes(key) && perStateDataFilter(key)
								? { [key]: stateHistory[key] }
								: undefined;
						return Object.assign({}, acc, _val);
					}, {});

					const _values = Object.values(elements);
					const _defValues = emptyValPerType();
					switch (typeof _filter) {
						case 'string':
							{
								return _values.length == 1 ? of(elements[_filter]) : _defValues ? of(_defValues) : EMPTY;
							}
							break;
						case 'object':
							{
								return !_values.includes(undefined) ? of(elements) : _defValues ? of(_defValues) : EMPTY;
							}
							break;
						default:
							return EMPTY;
					}
				}),
			);
		} else {
			let _data = this.apiStateArchive$.value;
			_data = Object.keys(_data).reduce((acc, key) => {
				const _val =
					_filter.toString().includes(key) && (_data[key] == null || Object.values(_data[key]).length != 0)
						? { [key]: _data[key] }
						: undefined;
				return Object.assign({}, acc, _val);
			}, {});
			return typeof _filter == 'string' ? _data[_filter] || {} : _data;
		}
	}

	resetApiState(_filter: keyof typeof apiAction | (keyof typeof apiAction)[]) {
		switch (typeof _filter) {
			case 'string':
				{
					this.apiState$.next({ action: _filter, response: null });
				}
				break;
			case 'object':
				{
					_filter.forEach((_apiAction) => this.apiState$.next({ action: _apiAction, response: null }));
				}
				break;
		}
	}

	get filterLocked() {
		return this.filterLocked$;
	}

	get routeContextState() {
		return this.routeContextState$;
	}

	setRouteContextState(_routeContext: IFilterResponse) {
		this.routeContextState$.next(_routeContext);
	}

	clearRouteContextState(route: routes) {
		const _value = { ...this.routeContextState$.value };
		delete _value[route];
		this.routeContextState$.next(_value);
	}

	get isLokaliseAuthorized() {
		return (
			!!this.lokaliseContextState.value?.formValues?.lokaliseToken &&
			!!this.lokaliseContextState.value?.initialValues?.__keys
		);
	}

	get isLokaliseAuthorized$() {
		return this.lokaliseContextState$.pipe(
			map((val) => !!val?.formValues?.lokaliseToken && !!val?.initialValues?.__keys),
		);
	}

	get lokaliseContextState() {
		return this.lokaliseContextState$;
	}

	setLokaliseContextState(_lokaliseContext: IFilterResponse) {
		this.lokaliseContextState$.next(_lokaliseContext);
	}

	clearLokaliseContextState(route: routes) {
		this.lokaliseContextState$.next(null);
	}

	get routerState() {
		return this.location
			.path()
			.split('/')
			.slice(-1)
			.join('')
			.replace(/(\?.+)|\?/g, '');
	}

	get routerConfig() {
		return _routerOptions.find((el) => el.route == this.routerState);
	}

	get contentLoading() {
		return this.contentLoading$;
	}

	get contextLoading() {
		return this.contextLoading$;
	}

	get contentLocked() {
		return this.contentLocked$;
	}

	get formState() {
		return this.formState$;
	}

	get editorState() {
		return this.editorState$;
	}

	get editorStateValue(): { [k in editorState]?: string } {
		return { [this.editorState$.value]: true };
	}

	setEditorState(_state: keyof typeof editorState) {
		this.editorState$.next(_state);
	}

	formsValue(_formName: keyof typeof formType | (keyof typeof formType)[], asValue = false) {
		if (!asValue) {
			return this.formsStateArchive$.pipe(
				untilDestroyed(this),
				distinctUntilChanged((prev, curr) => {
					const _filterVal: (keyof typeof formType)[] = (Array(_formName) as any).flat();
					return _filterVal.every((key) => prev[key] == curr[key]);
				}),
				// Pairwise(),
				// filter((formStateHistoryPair: any[]) => {
				// 	const _filterVal: (keyof typeof formType)[] = (Array(_formName) as any).flat();

				// 	FormStateHistoryPair = formStateHistoryPair.map((pairElem) =>
				// 		Object.keys(pairElem).reduce(
				// 			(acc, key: any) =>
				// 				Object.assign(
				// 					{},
				// 					acc,
				// 					_filterVal.includes(key) && {
				// 						[key]: pairElem[key],
				// 					},
				// 				),
				// 			{},
				// 		),
				// 	);
				// 	return _filterVal.some(
				// 		(key) =>
				// 			JSON.stringify(formStateHistoryPair[0][key]) !== JSON.stringify(formStateHistoryPair[1][key]),
				// 	);
				// }),
				// map((formStateHistoryPair: any[]) => formStateHistoryPair[1]),
				switchMap((stateHistory) => {
					const elements = Object.keys(stateHistory).reduce(
						(acc, key) =>
							Object.assign(
								{},
								acc,
								_formName.toString().includes(key) ? { [key]: stateHistory[key] } : undefined,
							),
						{},
					);
					if (Object.keys(elements).length > 0) {
						return typeof _formName == 'string' ? of(elements[_formName]) : of(elements);
					}
					return EMPTY;
				}),
			);
		} else {
			let _data = this.formsStateArchive$.value;
			_data = Object.keys(_data).reduce(
				(acc, key) =>
					Object.assign({}, acc, _formName.toString().includes(key) ? { [key]: _data[key] } : undefined),
				{},
			);
			return typeof _formName == 'string' ? _data[_formName] : _data;
		}
	}

	clearApiState(_apiName: keyof typeof apiAction) {
		if (this.apiStateArchive$.value[apiAction[_apiName]]) {
			this.apiState$.next({ action: apiAction[_apiName], response: null });
		}
	}

	clearFormState(_formName: keyof typeof formType) {
		if (this.formsStateArchive$.value[_formName]) {
			this.formState$.next({ [_formName]: null });
		}
	}

	copyText(_text) {
		const _input: HTMLInputElement = document.createElement('input');
		_input.value = _text;
		document.body.append(_input);
		_input.select();
		_input.setSelectionRange(0, 99999);
		document.execCommand('copy');
		_input.remove();
		this.message.set('Copied to clipboard', _text, 'success');
	}

	reverseEnum(_enum) {
		return Object.keys(_enum).reduce((acc, key) => Object.assign({}, acc, { [_enum[key]]: key }), {});
	}

	ngOnDestroy() {
		this.apiState$.complete();
	}
}
