import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'array-builder',
	template: ``,
	styles: [],
})
export class ArrayBuilderComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
