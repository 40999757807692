import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { _routerOptions } from '@app/app.config';

@Injectable({
	providedIn: 'root',
})
export class NavigationGuard implements CanActivate {
	constructor(private user: UserService, private router: Router) {}

	canActivate(): boolean {
		if (!this.user.toRead('editor')) {
			const _allowedPageToNavigate = _routerOptions.find((_) => this.user.toRead(_.route));
			this.router.navigate(['editor', _allowedPageToNavigate.route]);
			return false;
		}
		return true;
	}
}
