import { Directive, ViewContainerRef, Input } from '@angular/core';
import { FormService } from '../services/forms.service';
import { _formsFieldToDBMap, formType } from '@app/app.config';
import { FormGroupDirective } from '@angular/forms';

@Directive({
	selector: '[fieldsVisible]',
})
export class FormFieldVisibleDirective {
	constructor(private viewContainer: ViewContainerRef, private formService: FormService) {}

	@Input() fieldsVisible: string | string[];
	@Input() fieldsCompRule: 'OR' | 'AND' = 'OR';

	ngOnInit() {
		// this.formService.registerComponentFormGroup(this.viewContainer.injector.get(FormGroupDirective))
		if (this.formService.formName) {
			let fieldVisiable;
			switch (Array.isArray(this.fieldsVisible)) {
				case false:
					fieldVisiable = this.formService.showFieldIfFormAllows(this.fieldsVisible as string);
					break;
				case true:
					fieldVisiable = this.fieldsVisible[this.fieldsCompRule == 'OR' ? 'some' : 'every']((_fieldName) =>
						this.formService.showFieldIfFormAllows(_fieldName),
					);
					break;
			}
			if (!fieldVisiable) this.viewContainer.element.nativeElement.remove();
		}
	}
}
