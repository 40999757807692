import { Injectable, OnDestroy, Inject, QueryList } from '@angular/core';
import {
	_routerOptions,
	formType,
	ROUTE_EVENT,
	routeState,
	_typify,
	_formsFieldToDBMap,
	_db,
	jurisdictions,
	_formsFieldToDbFieldMap,
	routes,
	contentBuilderType,
	pagesToProjectSelect,
	projects,
	formDataType,
	optionTypes,
	optionTypesList,
	permission,
	userRoles,
} from '@app/app.config';
import {
	FormGroup,
	FormBuilder,
	Validators,
	FormArray,
	FormGroupDirective,
	FormControl,
} from '@angular/forms';
import { ToolsService } from './tools.service';
import { map, mergeMap, debounceTime, distinctUntilChanged, tap, filter } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, of, empty, iif, EMPTY, merge } from 'rxjs';
import { IApiDBCollResponse, IDBCollListData } from '../app.interface';
import { ToastService } from './toast.service';
import { UserService } from './user.service';
import striptags from 'striptags';

@Injectable({
	providedIn: 'root',
})
export class FormsProviderService implements OnDestroy {
	private _authForm: FormGroup = null;
	private _filterForm: FormGroup = null;
	private _editorForm: FormGroup = null;
	private _listForm: FormGroup = null;
	private _searchForm: FormGroup = null;
	private _videoForm: FormGroup = null;
	private _articleForm: FormGroup = null;

	private _formsData: { [K in formType | formDataType]?: any } = { lokalise: {} };

	private lockFormValueChangesToEmit$ = new BehaviorSubject<boolean>(false);

	constructor(
		@Inject(ROUTE_EVENT) private routeEventsState: { [k in routeState]: (() => void)[] },
		private tools: ToolsService,
		private fb: FormBuilder,
		private user: UserService,
	) {
		routeEventsState.ActivationStart.push(() => {
			this.clearFormControls('filter');
			this.clearFormControls('editor');
			this.clearFormControls('list');

			if (this._searchForm) this.clearFormControls('search');

			this.tools.setEditorState('wait');
		});

		// Assign value to the form
		tools.apiStates('_data').subscribe((_) => {
			this.assignValuesToDataForm(_);
		});
		tools.apiStates('editorItem').subscribe((_) => {
			if (!_) return;
			this.clearFormControls('editor');
			// Clear values before apply the new one (prevent data liking from prev step)
			this.assignValuesToForm('editor', _);
			// _typify<FormGroup>(this['editor' + 'Form']).updateValueAndValidity()
			this.tools.setEditorState('edit');
		});
		tools.apiStates('list').subscribe((_) => {
			this.assignValuesToForm('list', _);
		});
		tools.apiStates('lokalise').subscribe((_) => {
			this.assignLokaliseValuesToForm('editor', _);
		});
	}

	// Do something to form value before return to the client
	private listenFormValueChanges(_formName: keyof typeof formType) {
		const form: FormGroup = this[_formName + 'Form'];

		switch (_formName) {
			case formType.filter:
				{
					return form.valueChanges.pipe(
						map((_) => {
							Object.keys(_).forEach((key) => {
								if (_[key] === null) delete _[key];
								else if (Array.isArray(_[key])) {
									if (!_[key].length) delete _[key];
									else _[key] = _[key].map((el) => el._id);
								}
							});
							return _;
						}),
					);
				}
				break;
			case formType.editor:
				{
					return form.valueChanges.pipe(
						map((_) => {
							if (!this.tools.routerConfig) return {};
							const _collFields = this.tools.routerConfig.formFields || [];
							Object.keys(_).forEach((key) => {
								if (!_collFields.includes(key)) delete _[key];
							});
							return _;
						}),
					);
				}
				break;
			default:
				return form.valueChanges;
		}
	}

	private isUserCanReadOnly(_val, nullValue = null) {
		return this.user.toRead() && !this.user.toCreate() && !this.user.toUpdate()
			? { value: _val || nullValue, disabled: true }
			: { value: _val || nullValue, disabled: false };
	}

	private mapIdToInitObject(itemKey: string, itemValue: string | string[], _initialValues = null) {
		const _formValue = (_initialValues || this.initialValues)[_formsFieldToDBMap[itemKey]];
		if (!_formValue) return itemValue;
		if (typeof itemValue === 'string') return _formValue.find((el) => el._id === itemValue);
		else if (itemValue && itemValue.constructor === Array) {
			return itemValue.map((_item) => {
				return _formValue.find((el) => el._id === _item) || _item;
			});
		}
		return itemValue;
	}

	private generateFunctionalValues(_formName: keyof typeof formType) {
		const _form: FormGroup = this[_formName + 'Form'];
		const _functionalFields = Object.keys(_form.controls).filter((key) => key.indexOf('__') === 0);
		switch (_formName) {
			case formType.editor:
				{
					_functionalFields.forEach((_key) => {
						switch (_key) {
							case '__filterPages':
								{
									this._editorForm
										.get('pages')
										.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged())
										.subscribe((_) => {
											const _dataPages = this.initialValues.pages;
											if (_dataPages && _) {
												let _val = 'custom';
												if (
													_dataPages.some((page) => page._project.name.endsWith(projects.portal)) &&
													_.length ===
														_dataPages.filter((page) => page._project.name.endsWith(projects.portal)).length
												) {
													_val = 'portal';
												} else if (
													_dataPages.some((page) => page._project.name.endsWith(projects.main)) &&
													_.length ===
														_dataPages.filter((page) => page._project.name.endsWith(projects.main)).length
												) {
													_val = 'main';
												} else if (
													_dataPages.some((page) => page._project.name.endsWith(projects.lp)) &&
													_.length ===
														_dataPages.filter((page) => page._project.name.endsWith(projects.lp)).length
												) {
													_val = 'lp';
												} else if (_.length === _dataPages.length) _val = 'all';
												else if (_.length === 0) _val = 'custom';
												_form.get(_key).patchValue(_val);
											} else {
												_form.get(_key).patchValue('custom');
											}
										});
								}
								break;
						}
					});
				}
				break;
		}
	}

	private assignLokaliseValuesToForm(_formName: keyof typeof formType, _data: any) {
		const _form: FormGroup = this[_formName + 'Form'];
		const _lokaliseData = this.tools.apiStates('lokalise', true);

		const { initialValuesKeys, formValues } = {
			initialValuesKeys: Object.keys(_lokaliseData?.initialValues || {}),
			formValues: Object.keys(_lokaliseData?.formValues || {}),
		};

		if (initialValuesKeys?.length) {
			// Generate values for Lokalise kind keys
			this._formsData.lokalise = _lokaliseData.initialValues;
		}

		if (formValues?.length) {
			const formData = {};
			// Generate values for Lokalise kind keys
			Object.keys(_lokaliseData.formValues).forEach((key) => {
				formData[key] = _lokaliseData.formValues[key];
			});

			_form.patchValue(formData);
		}
	}

	private assignValuesToDataForm(_data: any) {
		// Craft some params
		_data = Object.keys(_data).reduce((acc, key) => {
			switch (key) {
				case 'languages':
					{
						this.searchForm.patchValue({
							language: _data.languages
								.filter((lang) => !lang.deprecated)
								.find((lang) => lang.name === 'English'),
						});
					}
					break;
				case 'pages':
					{
						if (_data.projects) {
							return Object.assign({}, acc, {
								[key]: _data[key].map((page) => {
									return page._project
										? Object.assign({}, page, {
												_project: this.mapIdToInitObject('project', page._project, _data),
										  })
										: page;
								}),
							});
						}
					}
					break;
				case 'projects':
					{
						if (!_data[key].some((_) => _._id === null)) {
							_data[key].unshift({ _id: null, name: 'No Project' });
						}
					}
					break;
			}

			return Object.assign({}, acc, { [key]: _data[key] });
		}, {});

		this._formsData.data = _data;
	}

	private assignValuesToForm(_formName: keyof typeof formType, _data: any) {
		if (!_data) return this.clearFormControls(_formName);
		this.lockFormValueChangesToEmit$.next(true);
		const _form: FormGroup = this[_formName + 'Form'];
		const _lokaliseData = this.tools.apiStates('lokalise', true);

		switch (_formName) {
			case formType.editor:
				{
					const _FORM_FORCE_STRING_TYPE_MAP_ = ['pages', 'project'];

					const _arrayKindControls = {};
					const _objectKindControls = {};

					_data = Object.keys(_data).reduce((acc, key) => {
						const _key = key.replace('_', '');

						// Generate content for specific fields
						switch (_key) {
							case 'options':
								{
									if (_data[key]) {
										// Matching types
										_data[key] = Object.keys(_data[key]).map((_optionKey) => {
											let _type;
											let value = _data[key][_optionKey];
											switch (value && _data[key][_optionKey].constructor.name) {
												case 'Number':
													_type = optionTypes.number;
													break;
												case 'Boolean':
													_type = optionTypes.checkbox;
													break;
												case 'Array':
													_type = optionTypes.array;
													break;
												case 'String': {
													if (_data[key][_optionKey].search(/\d{2}:\d{2}:\d{2}\s\w{3}\+\d+/) === 0) {
														_type = optionTypes.time;
														value = new Date('1970-01-01 ' + value.replace(/(\d{2}:\d{2}:\d{2}).+/, '$1'));
													} else if (!Number.isNaN(Date.parse(value))) {
														_type = optionTypes.date;
														value = new Date(value);
													} else _type = optionTypes.text;
													break;
												}
											}
											return {
												key: _optionKey,
												type: optionTypesList.find((_) => _._id === _type),
												value,
											};
										});
									} else _data[key] = [];
								}
								break;
						}

						if (!_FORM_FORCE_STRING_TYPE_MAP_.includes(_key)) {
							if (Array.isArray(_data[key])) {
								_arrayKindControls[_key] = _data[key];
								_typify<FormArray>(_form.get(_key)).clear();
							} else if (typeof _data[key] === 'object' && _data[key] !== null) {
								_objectKindControls[_key] = _data[key];
								_typify<FormGroup>(_form.get(_key))?.reset({});
							}
						}

						// Generate content for specific fields
						switch (_key) {
							case 'pages':
								{
									if (_data[key]) {
										const _dataPages = this.initialValues.pages;
										if (_data[key].join(';').indexOf(pagesToProjectSelect.all) === 0) _data[key] = _dataPages;
										else if (_data[key].join(';').indexOf(pagesToProjectSelect.portalAll) === 0) {
											_data[key] = _dataPages.filter((page) => page._project.name.endsWith(projects.portal));
										} else if (_data[key].join(';').indexOf(pagesToProjectSelect.mainAll) === 0) {
											_data[key] = _dataPages.filter((page) => page._project.name.endsWith(projects.main));
										} else if (_data[key].join(';').indexOf(pagesToProjectSelect.lpAll) === 0) {
											_data[key] = _dataPages.filter((page) => page._project.name.endsWith(projects.lp));
										}
									}
								}
								break;
							case 'useContentFrom':
								{
									if (_data[key]) {
										const _dataFields: [] = this.formData('list');
										_data[key] = _dataFields.find((_: any) => _._id === _data[key]) || _data[key];
									}
								}
								break;
							case 'content':
								{
									if (Array.isArray(_data[key])) {
										_data[key] = _data[key]
											.filter((el) => !!el)
											.map((_: any) => Object.assign(_, { locked: false }));
									}
								}
								break;
						}

						return Object.assign({}, acc, { [_key]: this.mapIdToInitObject(_key, _data[key]) });
					}, {});

					if (_lokaliseData?.formValues) {
						// Generate values for Lokalise kind keys
						Object.keys(_lokaliseData.formValues).forEach((key) => {
							_data[key] = _lokaliseData.formValues[key];
						});
					}

					_form.patchValue(_data);
					// Generate values for FormArray
					Object.keys(_arrayKindControls).forEach((key) => {
						switch (key) {
							case 'meta':
							case 'content':
								{
									const _requiredFields =
										key === 'meta' ? { title: null, description: null } : { value: null, locked: false }; // Load the structure later from API
									_arrayKindControls[key] = _arrayKindControls[key].filter((item) => !!item);

									const _elemTemplate = _arrayKindControls[key].reduce(
										(acc, item) =>
											Object.assign(
												{},
												acc,
												Object.keys(item).reduce(
													(_a, _key) => Object.assign({}, _requiredFields, _a, { [_key]: null }),
													{},
												),
											),
										{},
									);

									if (this.initialValues.languages) {
										const _missingLang = this.initialValues.languages
											.filter((lang) => !lang.deprecated)
											.filter(
												(lang) =>
													_arrayKindControls[key].find((val) => val && val._language === lang._id) ===
													undefined,
											);
										_missingLang.forEach((lang) =>
											_arrayKindControls[key].push(Object.assign({}, _elemTemplate, { _language: lang._id })),
										);
									}

									if (this.initialValues.jurisdictions) {
										const _defaultJdx = this.initialValues.jurisdictions.find(
											(el) => el.name === jurisdictions.CIMA,
										);
										// Group all element by lang id and convert to { LANG_ID: [JDX_ID] }
										let _groupJdxToLang = _arrayKindControls[key].reduce((acc, el, idx) => {
											if (el._jurisdiction === null) {
												if (el.value || el.title) el._jurisdiction = _defaultJdx._id;
												else delete _arrayKindControls[key][idx];
											}
											return Object.assign({}, acc, {
												[el._language]: [el._jurisdiction, ...(acc[el._language] || [])],
											});
										}, {});

										// Revert [JDX_ID] of every LANG_ID into [MISSING_JDX_ID]
										_groupJdxToLang = Object.keys(_groupJdxToLang).reduce(
											(acc, key) =>
												Object.assign({}, acc, {
													[key]: this.initialValues.jurisdictions.filter(
														(jdxElem) => !_groupJdxToLang[key].includes(jdxElem._id),
													),
												}),
											{},
										);

										// Generate new elems
										Object.keys(_groupJdxToLang).forEach((langId) => {
											_groupJdxToLang[langId].forEach((jrx) => {
												_arrayKindControls[key].push(
													Object.assign({}, _elemTemplate, { _language: langId, _jurisdiction: jrx._id }),
												);
											});
										});
									}
								}
								break;
						}
						_arrayKindControls[key].forEach((item) => {
							const _val =
								typeof item === 'object'
									? Object.keys(item).reduce(
											(acc, _key) =>
												Object.assign({}, acc, {
													[_key.replace('_', '')]: this.fb.control(
														this.isUserCanReadOnly(this.mapIdToInitObject(_key.replace('_', ''), item[_key])),
													),
												}),
											{},
									  )
									: this.mapIdToInitObject(key, item);

							_typify<FormArray>(_form.get(key)).push(
								typeof item === 'object'
									? this.fb.group(_val)
									: this.fb.control(this.isUserCanReadOnly(_val)),
							);
						});
					});
					// Generate values for FormGroup
					Object.keys(_objectKindControls).forEach((key) => {
						switch (key) {
							case 'permissions':
								this.user.securedRoutesDBCollsList.forEach((itemKey) => {
									_typify<FormGroup>(_form.get(key)).addControl(
										itemKey,
										this.fb.control(this.isUserCanReadOnly(_objectKindControls[key][itemKey], [])),
									);
								});
								break;
							default:
								Object.keys(_objectKindControls[key]).forEach((itemKey) => {
									_typify<FormGroup>(_form.get(key)).addControl(
										itemKey,
										this.fb.control(this.isUserCanReadOnly(_objectKindControls[key][itemKey])),
									);
								});
								break;
						}
					});
				}
				break;
			case formType.list:
				{
					this._formsData[_formName] = _data;
				}
				break;
		}
		this.lockFormValueChangesToEmit$.next(false);
	}

	private initForm(_formName: keyof typeof formType): FormGroup {
		switch (_formName) {
			case formType.auth:
				{
					this._authForm = this.fb.group({
						email: [null, [Validators.required, Validators.minLength(2)]],
						password: [null, [Validators.required, Validators.minLength(2)]],
					});
					this.listenFormValueChanges(_formName)
						.pipe(
							untilDestroyed(this),
							mergeMap((_) => (this.lockFormValueChangesToEmit$.value ? EMPTY : of(_))),
						)
						.subscribe((_) => {
							if (!this.tools.filterLocked.value) this.tools.formState.next({ [_formName]: _ });
						});
				}
				break;
			case formType.filter:
				{
					this._filterForm = this.fb.group({
						project: [null],
						builder: [null],
						jurisdiction: [null],
						onReview: [false],
						page: [null],
						author: [null],
						role: [null],
						prefix: [null],
					});
					// Functional values
					this.generateFunctionalValues(formType.filter);

					this.listenFormValueChanges(_formName)
						.pipe(
							untilDestroyed(this),
							mergeMap((_) => (this.lockFormValueChangesToEmit$.value ? EMPTY : of(_))),
						)
						.subscribe((_) => {
							this.clearFormControls('search');
							if (!_.onReview) delete _.onReview;
							if (!this.tools.filterLocked.value) this.tools.formState.next({ [_formName]: _ });
						});
				}
				break;
			case formType.list:
				{
					this._listForm = this.fb.group({
						isBulk: [false],
						bulkFile: [null],
						selectedItem: [null],
					});
					this.listenFormValueChanges(_formName)
						.pipe(
							untilDestroyed(this),
							mergeMap((_) => (this.lockFormValueChangesToEmit$.value ? EMPTY : of(_))),
						)
						.subscribe((_) => {
							if (!this.tools.filterLocked.value) this.tools.formState.next({ [_formName]: _ });
						});
				}
				break;
			case formType.editor:
				{
					this._editorForm = this.fb.group({
						id: [null],
						// Title
						key: [null],
						uri: [null],
						name: [null],
						description: [null],
						prefix: [null],
						// Context
						builderType: [null],
						useContentFrom: [null],
						content: this.fb.array([]),
						meta: this.fb.array([]),
						email: [null],
						password: [null],
						role: [null],
						hash: [null],
						value: [null],
						native: [null],
						rtl: [null],
						deprecated: [null],
						terminal: [null],
						isLP: [null],
						lokaliseToken: [null],
						lokaliseProjects: [null],
						lokaliseLanguages: [null],
						lokaliseWebhooks: [null],
						lokaliseOnlyVerified: [null],
						// Options
						pages: [null],
						project: [null],
						parent: [null],
						options: this.fb.array([]),
						permissions: this.fb.group({}),
						domain: this.fb.array([]),
						// Meta
						author: [null],
						createdAt: [null],
						updatedAt: [null],
						onReview: [null],
						// Functional props ( MUST start with '__' !!! )
						__filterPages: [null],
					});
					// Functional values
					this.generateFunctionalValues(formType.editor);

					this.listenFormValueChanges(_formName)
						.pipe(
							untilDestroyed(this),
							mergeMap((_) => (this.lockFormValueChangesToEmit$.value ? EMPTY : of(_))),
						)
						.subscribe((_) => {
							if (!this.tools.filterLocked.value) this.tools.formState.next({ [_formName]: _ });
						});
				}
				break;
			case formType.search:
				{
					this._searchForm = this.fb.group({
						query: [null, Validators.minLength(4)],
						language: [null],
						caseSensitive: [false],
						threshold: [0.2],
						collection: [null],
						fields: ['content.value name key'],
					});
					this.listenFormValueChanges(_formName)
						.pipe(
							untilDestroyed(this),
							mergeMap((_) => (this.lockFormValueChangesToEmit$.value ? EMPTY : of(_))),
							debounceTime(450),
							distinctUntilChanged(),
						)
						.subscribe((_) => {
							if (!this.tools.filterLocked.value) this.tools.formState.next({ [_formName]: _ });
						});
				}
				break;
			case formType.video:
				{
					this._videoForm = this.fb.group({
						name: [null, [Validators.required, Validators.minLength(6)]], // Video Title
						order: [null, [Validators.required, Validators.min(0)]],
						videoAuthor: [null, [Validators.required, Validators.minLength(8)]],
						videoID: [null, [Validators.required, Validators.minLength(10)]],
						file: [null],
					});
					this.listenFormValueChanges(_formName)
						.pipe(
							untilDestroyed(this),
							mergeMap((_) => (this.lockFormValueChangesToEmit$.value ? EMPTY : of(_))),
						)
						.subscribe((_) => {
							if (!this.tools.filterLocked.value) this.tools.formState.next({ [_formName]: _ });
						});
				}
				break;
				case formType.article:
					{
						this._articleForm = this.fb.group({
							title: [null, [Validators.required, Validators.minLength(6)]],
							order: [null, [Validators.required, Validators.min(0)]],
							date: [null, [Validators.required, Validators.pattern('\d{2}\/\d{2}\/\d{2}')]],
							file: [null, Validators.required],
						});
						this.listenFormValueChanges(_formName)
							.pipe(
								untilDestroyed(this),
								mergeMap((_) => (this.lockFormValueChangesToEmit$.value ? EMPTY : of(_))),
							)
							.subscribe((_) => {
								if (!this.tools.filterLocked.value) this.tools.formState.next({ [_formName]: _ });
							});
					}
					break;
		}
		return this[`_${_formName}Form`];
	}

	private filterFormValues(_formName: keyof typeof formType) {
		const _val = this[_formName + 'Form'].getRawValue();
		const _exception = ['useContentFrom', 'lokaliseOnlyVerified', 'deprecated'];
		return (Object.keys(_val) as any).reduce((acc, key) => {
			const _provideVal =
				_exception.includes(key) ||
				(key.indexOf('__') !== 0 &&
					!!_val[key] &&
					Array(_val[key]).length > 0 &&
					Object.keys(Object(_val[key])).length > 0);
			return _provideVal ? Object.assign({}, acc, { [key]: _val[key] }) : acc;
		}, {});
	}

	get authForm(): FormGroup {
		return this._authForm || this.initForm(formType.auth);
	}

	get filterForm(): FormGroup {
		return this._filterForm || this.initForm(formType.filter);
	}

	get editorForm(): FormGroup {
		return this._editorForm || this.initForm(formType.editor);
	}

	get listForm(): FormGroup {
		return this._listForm || this.initForm(formType.list);
	}

	get searchForm(): FormGroup {
		return this._searchForm || this.initForm(formType.search);
	}

	get videoForm(): FormGroup {
		return this._videoForm || this.initForm(formType.video);
	}

	get articleForm(): FormGroup {
		return this._articleForm || this.initForm(formType.article);
	}

	get noFieldValue(): FormControl {
		return this.fb.control(this.isUserCanReadOnly(null));
	}

	get initialValues() {
		return (this._formsData[formDataType.data] ||
			Object.keys(_db).reduce(
				(acc, key) => Object.assign(acc, { [key]: null }),
				{},
			)) as IApiDBCollResponse['_data'];
	}

	get initialLokaliseValues() {
		return (
			this._formsData[formDataType.lokalise] ||
			Object.keys(_db).reduce((acc, key) => Object.assign(acc, { [key]: null }), {})
		);
	}

	// Remove data from formControl
	clearFormControls(_formName: keyof typeof formType) {
		const _form: FormGroup = this[_formName + 'Form'];
		this.lockFormValueChangesToEmit$.next(true);
		switch (_formName) {
			case formType.search:
				{
					_form.get('query').reset(null);
				}
				break;
			default: {
				Object.keys(_form.controls).forEach((key) => {
					const _control = _form.controls[key];
					switch (true) {
						case _control instanceof FormArray:
							_typify<FormArray>(_control).clear();
							break;
						case _control instanceof FormGroup:
							_typify<FormGroup>(_control).reset({});
							break;
						default:
							_control.reset(null);
							break;
					}
				});
				break;
			}
		}
		this.lockFormValueChangesToEmit$.next(false);
		_form.updateValueAndValidity();
	}

	generateEmptyForm(_formName: keyof typeof formType) {
		this.clearFormControls(_formName);
		const _form = _typify<FormGroup>(this[_formName + 'Form']);
		this.lockFormValueChangesToEmit$.next(true);
		switch (_formName) {
			case formType.editor:
				{
					Object.keys(_form.value).forEach((key) => {
						if (!this.tools.routerConfig.formFields.includes(key)) return;
						switch (key) {
							case 'id':
								{
									_form.get(key).patchValue(Math.random() * 123456789 + '');
								}
								break;
							case 'builderType':
								{
									if (this.initialValues.buildertypes) {
										_form
											.get(key)
											.patchValue(
												this.initialValues.buildertypes.find((_) => _.name === contentBuilderType.string),
											);
									}
								}
								break;
							case 'options':
							case 'pages':
								{
									_form.get(key).patchValue([]);
								}
								break;
							case 'meta':
							case 'content':
								{
									const _template =
										key === 'meta'
											? { language: null, jurisdiction: null, title: null, description: null }
											: { language: null, jurisdiction: null, value: null, locked: false }; // Load the structure later from API

									if (this.initialValues.languages && this.initialValues.jurisdictions) {
										const _context = [];
										this.initialValues.languages
											.filter((lang) => !lang.deprecated)
											.forEach((lang) => {
												this.initialValues.jurisdictions.forEach((jdx) => {
													_context.push(
														this.fb.group(
															Object.assign({}, _template, {
																language: this.mapIdToInitObject('language', lang._id),
																jurisdiction: this.mapIdToInitObject('jurisdiction', jdx._id),
															}),
														),
													);
												});
											});
										_context.forEach((el) => _typify<FormArray>(_form.get(key)).push(el));
									}
								}
								break;
							case 'permissions':
								{
									const _formControl = _typify<FormGroup>(_form.get(key));
									this.user.securedRoutesDBCollsList.forEach((controlKey) => {
										_formControl.addControl(controlKey, this.fb.control(null));
									});
								}
								break;

							default: {
								_form.get(key).patchValue(null);
								break;
							}
						}
					});
				}
				break;
		}
		this.lockFormValueChangesToEmit$.next(false);
		_form.updateValueAndValidity();
	}

	generateSubmitValues(_formName: keyof typeof formType) {
		switch (_formName) {
			case formType.editor:
				{
					const _formValue = this.filterFormValues(_formName);
					const _data = {};

					const objectLikeValues = [];
					const arrayLikeValues = [];
					Object.keys(_formValue).forEach((key) => {
						let _value = _formValue[key];
						// Extra logic for specific keys
						switch (key) {
							case 'hash':
								{
									if (_value.length == 0) return;
								}
								break;
							case 'id':
								{
									if (this.tools.editorStateValue.add) return;
								}
								break;
							case 'key':
								{
									_value = _value.replace(/[^__]+__/i, '');
								}
								break;
							case 'pages':
								{
									const _dataPages = this.initialValues.pages;
									let _val = _value;
									if (
										_value.length ===
										_dataPages.filter((page) => page._project.name.endsWith(projects.portal)).length
									) {
										_val = [pagesToProjectSelect.portalAll];
									} else if (
										_value.length ===
										_dataPages.filter((page) => page._project.name.endsWith(projects.main)).length
									) {
										_val = [pagesToProjectSelect.mainAll];
									} else if (
										_value.length ===
										_dataPages.filter((page) => page._project.name.endsWith(projects.lp)).length
									) {
										_val = [pagesToProjectSelect.lpAll];
									} else if (_value.length === _dataPages.length) _val = [pagesToProjectSelect.all];
									_value = _val;
								}
								break;
							case 'options':
								{
									_value = _value.reduce(
										(acc, el) =>
											Object.assign(
												{},
												acc,
												el.key
													? {
															[el.key]:
																el.type._id === optionTypes.time
																	? el.value.toTimeString().replace(/(\d{2}:\d{2}:\d{2}\s\w{3}\+\d+).+/, '$1')
																	: el.type._id === optionTypes.array
																	? el.value.toString().split(',')
																	: el.value,
													  }
													: undefined,
											),
										{},
									);
								}
								break;
						}

						if (_value && typeof _value === 'object' && _value.constructor === Array) {
							arrayLikeValues.push({ key: [_formsFieldToDbFieldMap[key] || key], value: _value });
						} else if (_value && typeof _value === 'object' && _value.constructor === Object) {
							objectLikeValues.push({ key: [_formsFieldToDbFieldMap[key] || key], value: _value });
						} else if (typeof _value === 'boolean') {
							_data[_formsFieldToDbFieldMap[key] || key] = [null, true][+_value];
						} else {
							_data[_formsFieldToDbFieldMap[key] || key] = _value;
						}
					});
					objectLikeValues.forEach((el) => {
						const _keys = Object.keys(el.value);
						if (_keys.includes('_id')) _data[_formsFieldToDbFieldMap[el.key] || el.key] = el.value._id;
						else _data[_formsFieldToDbFieldMap[el.key] || el.key] = el.value;
					});
					arrayLikeValues.forEach((el) => {
						if (el.value.every((item) => item._id && item.name)) {
							_data[_formsFieldToDbFieldMap[el.key] || el.key] = el.value.map((item) => item._id);
						} else if (el.value.every((item) => item.language && item.jurisdiction)) {
							const _value = el.value.reduce((acc, item) => {
								if (item.value || item.title) {
									// Keys: meta, content
									acc.push(
										Object.keys(item).reduce((_a, _key) => {
											let _itemKeyValue =
												item[_key] && item[_key]._id && item[_key].name ? item[_key]._id : item[_key];
											if (_key === 'value') {
												_itemKeyValue = _itemKeyValue
													.replace(/(\n|\t|\r)/g, '')
													.replace(/(\s\s+)|(\$nbsp\;)'/g, ' ');
												if (_formValue.builderType.name === 'String') {
													_itemKeyValue = striptags(_itemKeyValue);
												}
											}
											return Object.assign({}, _a, {
												[_formsFieldToDbFieldMap[_key] || _key]: _itemKeyValue,
											});
										}, {}),
									);
								}
								return acc;
							}, []);
							_data[_formsFieldToDbFieldMap[el.key] || el.key] = _value;
						} else {
							_data[_formsFieldToDbFieldMap[el.key] || el.key] = el.value;
						}
					});

					if (
						this.tools.routerState === 'users' &&
						(!this.user.hasRole(userRoles.superadmin) || _formValue.name !== this.user.userInfo.name)
					) {
						delete _data['lokaliseProjects'];
						delete _data['lokaliseToken'];
						delete _data['lokaliseLanguages'];
						delete _data['lokaliseWebhooks'];
						delete _data['lokaliseOnlyVerified'];
					}
					return _data;
				}
				break;
		}
	}

	formData(_formName: keyof typeof formType) {
		return this._formsData[_formName];
	}

	ngOnDestroy() {}
}

// tslint:disable-next-line: max-classes-per-file
@Injectable()
export class FormService {
	private _formFromComponentView: keyof typeof formType;

	constructor(
		@Inject(ROUTE_EVENT) private routeEventsState: { [k in routeState]: (() => void)[] },
		private formsProvider: FormsProviderService,
		private tools: ToolsService,
		private message: ToastService,
	) {}

	init(_formName: keyof typeof formType) {
		this._formFromComponentView = _formName;
	}

	get _provider() {
		return this.formsProvider;
	}

	get form(): FormGroup {
		return this.formsProvider[this._formFromComponentView + 'Form'];
	}

	get formName() {
		return this._formFromComponentView;
	}

	get initialValues() {
		return this.formsProvider.initialValues;
	}

	get initialLokaliseValues() {
		return this.formsProvider.initialLokaliseValues;
	}

	get formInitialValues() {
		return this.formsProvider.formData(this._formFromComponentView);
	}

	get formIsEmpty(): boolean {
		return (
			(Object.values(this.form.value) as any)
				.flat()
				.filter((x) => !!x && Array(x).length > 0 && Object.keys(Object(x)).length > 0).length === 0
		);
	}

	get editorInitialValues(): IDBCollListData {
		return this.form.get('__data').value;
	}

	get formValid() {
		// TODO: CHANGE VALIDATION LOGIC TO 'VALIDATE.JS'
		const _collFields = this.tools.routerConfig.formFields;
		const _exception = [
			'useContentFrom',
			'rtl',
			'author',
			'createdAt',
			'updatedAt',
			'prefix',
			'hash',
			'deprecated',
		];
		if (!this.tools.lokaliseContextState.value?.token) {
			_exception.push(
				'lokaliseProjects',
				'lokaliseToken',
				'lokaliseLanguages',
				'lokaliseWebhooks',
				'lokaliseOnlyVerified',
			);
		}
		const _customErr = new Set();
		const _logicPerKey = (_key) => {
			const _val = this.getFieldValue(_key);
			switch (_key) {
				case 'pages':
					{
						return _val && _val.length > 0;
					}
					break;
				case 'meta':
					{
						return _val && _val.some((el) => el.title);
					}
					break;
				case 'content':
					{
						const _ref = this.getFieldValue('useContentFrom');
						let validationErr = false;
						// TODO: FIX CONTENT TYPE VALIDATORS AS ITS WRONG
						if (!['String', 'Html'].includes(this.getFieldValue('builderType').name)) {
							let _error = null;
							try {
								_val
									.filter(
										(el) => el.value && (this.tools.isLokaliseAuthorized ? el.language.value === 'en' : true),
									)
									.forEach((el) => {
										_error = el;
										if (
											(el.value.trim()[0] === '{' &&
												/^\{\s*\}$/.test(
													el.value
														.trim()
														.replace(/<\s*[^>]*>.*?<\s*\/\s*\w+>/g, '')
														.replace(/\\+/g, '')
														.replace(/"+/g, '\\"')
														.replace(/\\"([\w_-]+(?=\\")*)\\"\s*:\s*\\".+(?=\\")*\\"/, ''),
												)) ||
											(el.value.trim()[0] === '[' &&
												/^\[\s*\]$/.test(
													el.value
														.trim()
														.replace(/<\s*[^>]*>.*?<\s*\/\s*\w+>/g, '')
														.replace(/\\+/g, '')
														.replace(/"+/g, '\\"')
														.replace(/\\".+(?=\\")*\\",?/, ''),
												))
										) {
											JSON.parse(el.value);
										} else {
											throw new Error('');
										}
									});
							} catch (e) {
								validationErr = true;
								_customErr.add(` - ${_error.language.value}-${_error.jurisdiction.name} is invalid JSON`);
							}
						}
						return _ref && _ref._id ? true : _val && _val.some((el) => el.value) && !validationErr;
					}
					break;
				case 'options':
					{
						return !_val || _val.length === 0 || !_val.some((el) => !el.key);
					}
					break;
				case 'permissions':
					{
						return (
							_val &&
							Object.keys(_val).some(
								(key) =>
									_val[key] && (_val[key].includes(permission.read) || _val[key].includes(permission.all)),
							)
						);
					}
					break;
				default:
					return true;
			}
		};
		const _isValid = _collFields.every(
			(key) => _exception.includes(key) || (!!this.getFieldValue(key) && _logicPerKey(key)),
		);
		if (!_isValid) {
			_collFields.forEach((controlKey) => this.form.get(controlKey).markAsDirty());
			this.message.set(
				'Form Invalid',
				`Some values are not provided: [${_collFields
					.filter((key) => !_exception.includes(key) && (!this.getFieldValue(key) || !_logicPerKey(key)))
					.join('; ')}].${_customErr.size > 0 ? '\tSuggestions:' + [..._customErr].join(';\n') : ''}`,
			);
		}
		return _isValid;
	}

	get formSubmitValues() {
		return this._provider.generateSubmitValues(this._formFromComponentView);
	}

	clear() {
		this._provider.clearFormControls(this._formFromComponentView);
	}

	showFieldIfFormAllows(_field: string): boolean {
		let _appliedFilterArrayName = null;
		switch (this._formFromComponentView) {
			case formType.filter:
				_appliedFilterArrayName = 'filters';
				break;
			case formType.editor:
				_appliedFilterArrayName = 'formFields';
				break;
		}
		return (
			this.form &&
			!!this.form.get(_field) &&
			_appliedFilterArrayName &&
			_routerOptions.some(
				(el) =>
					el.route === this.tools.routerState &&
					!!el[_appliedFilterArrayName] &&
					el[_appliedFilterArrayName].includes(_field),
			)
		);
	}

	getField(_field) {
		if (!this._formFromComponentView) return this.formsProvider.noFieldValue;
		return this.form.get(_field) || this.formsProvider.noFieldValue;
	}

	getFieldValue(_field) {
		return this.getField(_field).value;
	}

	registerComponentFormGroup(_formsGroupList: QueryList<FormGroupDirective> | FormGroupDirective) {
		switch (_formsGroupList instanceof FormGroupDirective) {
			case true:
				{
					Object.keys(formType).some(
						(_formName: formType) =>
							this.formsProvider[(this._formFromComponentView = _formName) + 'Form'] ===
							(_formsGroupList as FormGroupDirective).form,
					);
				}
				break;
			case false:
				{
					(_formsGroupList as QueryList<FormGroupDirective>).find((_form) =>
						Object.keys(formType).some((_formName: formType) => {
							this._formFromComponentView = _formName;
							return this.formsProvider[_formName + 'Form'] === _form.form;
						}),
					);
				}
				break;
		}
	}
}
