import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ClientService } from '@app/shared/services/client.service';
import { FormService } from '@app/shared/services/forms.service';
import { LanguageService } from '@app/shared/services/helpers/language.service';
import { ToolsService } from '@app/shared/services/tools.service';
import { UserService } from '@app/shared/services/user.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { filter, map, pluck, switchMap, throttleTime } from 'rxjs/operators';
import { ROUTE_EVENT, routeState, permission } from '@app/app.config';

@Component({
	selector: 'app-side-menu',
	templateUrl: './side-menu.component.html',
	providers: [
		{ provide: 'listForm', useClass: FormService },
		{ provide: 'searchForm', useClass: FormService },
	],
})
export class SideMenuComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('searchInput') searchInput: ElementRef;
	listResults = [];
	permission = permission;
	searchLangSelected;
	searchLangSuggestion$ = new BehaviorSubject<any>(false);

	private unsubscribe$ = new Subscription();

	constructor(
		@Inject(ROUTE_EVENT) routeEventsState: { [k in routeState]: (() => void)[] },
		@Inject('listForm') public fsList: FormService,
		@Inject('searchForm') public fsSearch: FormService,
		public tools: ToolsService,
		public user: UserService,
		private client: ClientService,
		private langs: LanguageService,
	) {
		fsList.init('list');
		fsSearch.init('search');

		this.unsubscribe$.add(
			tools.apiStates('search').subscribe((_) => {
				if (_ && _.length) this.listResults = _;
			}),
		);

		this.unsubscribe$.add(
			tools.apiStates('list').subscribe((_) => {
				const _searchVal = this.fsSearch.getField('query').value;
				if (_ && _.length && (_searchVal ? _searchVal.length == 0 : true)) {
					this.listResults = _.map((el) => Object.assign({}, el, { key: el.name }));
				}
			}),
		);

		routeEventsState.NavigationEnd.push(() => {
			this.searchInput.nativeElement.value = null;
		});

		this.listResults = fsList.formInitialValues;
		this.searchLangSelected = this.fsList.initialValues.languages.find((lang: any) => lang.value == 'en');
	}

	onNew() {
		this.fsList.clear();
		this.fsList._provider.generateEmptyForm('editor');
		this.tools.setEditorState('add');
	}

	onDelete() {
		this.tools.setEditorState('load');
		this.client.deleteCollectionItem(this.fsList.getField('selectedItem').value);
		this.fsList.clear();
	}

	onSync() {
		const selectedItems = this.fsList.getField('selectedItem').value;
		if (selectedItems.length) {
			this.client.syncFieldsToLokalise(this.fsList.getField('selectedItem').value, true);
			this.fsList.clear();
		}
	}

	onExport() {
		this.tools.setEditorState('wait');
		this.client.exportCollectionItems(this.fsList.getField('selectedItem').value);
			this.fsList.clear();
	}

	ngOnInit() {
		this.unsubscribe$.add(
			this.fsList
				.getField('isBulk')
				.valueChanges.pipe(untilDestroyed(this))
				.subscribe((_) => {
					if (_) this.tools.setEditorState('wait');
					this.fsList.getField('selectedItem').reset();
				}),
		);
	}

	onItemOptionClick(event, item, option) {
		event.stopPropagation();
	}

	onInput(e) {
		if (e.target.value.length >= 4) this.fsSearch.getField('query').patchValue(e.target.value);
		else {
			this.searchLangSuggestion$.next(false);
			this.fsSearch.getField('query').reset();
			this.listResults = this.fsList.formInitialValues;
		}
	}

	ngAfterViewInit() {
		fromEvent(this.searchInput.nativeElement, 'input')
			.pipe(
				filter((_) => this.tools.routerState === 'fields'), // Make it work only on 'Fields' page
				pluck('target', 'value'),
				filter((_: string) => _.length >= 4),
				throttleTime(1500),
				switchMap((_) =>
					this.langs.detect(_).pipe(
						map((_) => {
							return _ && this.fsSearch.getFieldValue('language').value !== _['1'] ? _ : false;
						}),
					),
				),
			)
			.subscribe((_) => {
				this.searchLangSuggestion$.next(_);
			});
	}

	changeSearchLanguage(e) {
		if (e.value) this.fsSearch.getField('language').patchValue(e.value);
		else {
			const selectedLang = this.fsList.initialValues.languages.find((lang: any) => lang.value == e);
			if (selectedLang) {
				this.fsSearch.getField('language').patchValue(selectedLang);
				this.searchLangSelected = selectedLang;
			}
			this.searchLangSuggestion$.next(false);
		}
	}

	ngOnDestroy() {
		this.unsubscribe$.unsubscribe();
	}
}
