import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { _routerOptions } from '@app/app.config';
import { EMPTY } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { ToolsService } from '../services/tools.service';

@Injectable()
export class LokaliseValuesGuard implements Resolve<any> {
	constructor(private api: ApiService, private tools: ToolsService) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
		try {
			const _currentRouterOptions = _routerOptions.find(
				(el) => el.route === route.routeConfig.path || el.routeUrl === state.url,
			);
			const _lokaliseData = _currentRouterOptions.lokaliseRouteData;
			if (!_lokaliseData || _lokaliseData.length === 0) return true;
			else {
				return this.api.getLokaliseData(_lokaliseData).pipe(tap((res: any) => this.tools.apiState.next(res)));
			}
		} catch (e) {
			console.error(e);
			return null;
		}
	}
}
