import { Injectable, OnDestroy, Output } from '@angular/core';
import { messageCode } from '@app/app.config';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, concat, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, mapTo, switchMap } from 'rxjs/operators';

export enum EToastReason {
	deleteKey = 'deleteKey',
	syncToLokalise = 'syncToLokalise',
	saveAndSyncToLokalise = 'saveAndSyncToLokalise',
}

type confirmedType = {
	confirmed: boolean;
	reason?: EToastReason;
};
@Injectable()
export class ToastService implements OnDestroy {
	private _lifeTime = 5000;
	private _message = new BehaviorSubject<any>(null);
	private _assuranceConfirmed = new Subject<boolean>();

	public assuranceConfirmed$: Observable<confirmedType> = this._assuranceConfirmed.pipe(
		untilDestroyed(this),
		switchMap((confirmed) =>
			of({
				confirmed,
				reason: this._message.value?.reason,
			}),
		),
	);

	constructor(private messageService: MessageService) {
		this._message
			.pipe(untilDestroyed(this), debounceTime(250), distinctUntilChanged(), delay(300))
			.subscribe((val: any) => {
				if (val) {
					this.messageService.add({
						severity: val.severity,
						summary: val.summary,
						detail: val.detail,
						life: this._lifeTime,
						key: val.key,
						sticky: val.sticky,
						data: {
							reason: val.reason,
						},
					});
				}
			});
	}

	generateMessageFromMsgCode = (msgCode: number, _details: string = '') => {
		const _msg = messageCode.find((_) => _._id == msgCode);
		if (_msg) {
			return {
				summary: _msg.summaryMsg,
				detail: _msg.detailMsg + '\n' + this.titleCase(_details),
				severity: _msg.severity || (Math.floor(msgCode / 100) == 1 ? 'success' : 'error'),
			};
		}
	};

	set(
		summary: string,
		detail: string,
		severity = 'error',
		key = undefined,
		sticky = false,
		reason: EToastReason = undefined,
	) {
		this._message.next({ summary, detail, severity, key, sticky, reason });
	}

	setMany(messages: { summary: string; detail: string; severity: string }[]) {
		this._message.next(messages);
	}

	confirmAssurance() {
		this.messageService.clear('assurance');
		this._assuranceConfirmed.next(true);
	}

	rejectAssurance() {
		this.messageService.clear('assurance');
		this._assuranceConfirmed.next(false);
	}

	clear() {
		this._message.next(null);
		this.messageService.clear();
	}

	private titleCase(str: string) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	ngOnDestroy() {}
}
