import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApiService } from '../services/api.service';
import { _routerOptions } from '@app/app.config';

@Injectable()
export class ListContextGuard implements Resolve<any> {
	constructor(private api: ApiService) {}
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
		try {
			return this.api.collectionItems(_routerOptions.find((el) => el.route == route.routeConfig.path || el.routeUrl == state.url).dbColl);
		} catch (e) {
			console.error(e);
			return null;
		}
	}
}
