import { Component, OnDestroy } from '@angular/core';
import { IUserInfo, IVideoInfo } from '@app/shared/app.interface';
import { ClientService } from '@app/shared/services/client.service';
import { ToolsService } from '@app/shared/services/tools.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { DialogService } from 'primeng/dynamicdialog';
import { mergeMap } from 'rxjs/operators';
import { AddVideoComponent } from './add-video/add-video.component';

@Component({
	selector: 'video-editor',
	templateUrl: './videos.component.html',
	providers: [DialogService],
})
export class VideosFormComponent implements OnDestroy {
	videos: IVideoInfo[] = [];
	reorderIsActive = false;
	clonedVideos: { [s: string]: IVideoInfo } = {};
	users: IUserInfo[] = [];

	constructor(
		public tools: ToolsService,
		private client: ClientService,
		public dialogService: DialogService,
	) {
		tools
			.apiStates('_data')
			.pipe(
				untilDestroyed(this),
				mergeMap((_data: any) => {
					this.users = _data.users;
					return tools.apiStates('misc');
				}),
			)
			.subscribe((misc) => {
				this.videos = (
					misc.map((item) => {
						return Object.assign({}, item, {
							author: this.users.find((_user) => {
								return _user._id === item._author;
							}),
						});
					}) || []
				).sort((a, b) => {
					return b.order - a.order;
				});
			});
	}

	toggleAddNewContent() {
		const ref = this.dialogService.open(AddVideoComponent, {
			header: 'Add New Video',
			width: '680px',
			styleClass: 'addNewVideoDialog',
			data: {
				videos: this.videos,
			},
		});

		ref.onClose.subscribe((_) => {
			if (_) {
				this.client.addNewMisc(_);
			}
		});
	}

	onRowDeleteInit(video: IVideoInfo) {
		if (confirm('Are you sure to delete video #' + video.order)) {
			this.client.deleteMisc(video);
		}
	}

	onRowEditInit(video: IVideoInfo) {
		this.clonedVideos[video._id] = { ...video };
	}

	onRowEditSave(video: IVideoInfo) {
		this.client.updateMisc(video);
	}

	onRowEditCancel(video: IVideoInfo, index) {
		this.videos[index] = this.clonedVideos[video._id];
		delete this.clonedVideos[video._id];
	}

	rowReorder(e) {
		this.videos = this.videos.map((video, index) => ((video.order = (index + 1).toString()), video));
		this.client.reorderMisc(this.videos.map((video) => ({ _id: video._id, order: video.order })));
	}

	ngOnDestroy() {}
}
