import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../shared/services/user.service';
import { LANG_DETECTION_API_TOKEN, LOKALISE_API_TOKEN } from '../app.config';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	constructor(private userService: UserService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let _authParam;
		if (request.url.includes('detectlanguage')) {
			_authParam = {
				Authorization: `Bearer ${LANG_DETECTION_API_TOKEN}`,
			};
		}
		//  else if (request.url.includes('lokalise')) {
		// 	_authParam = {
		// 		'X-Api-Token': `${LOKALISE_API_TOKEN}`,
		// 	};
		// }
		else {
			_authParam = {
				Authorization: `Bearer ${this.userService.token}`,
			};
		}

		// add authorization header with jwt token if available
		if (this.userService.authorized) {
			request = request.clone({
				setHeaders: {
					..._authParam,
				},
			});
		}

		return next.handle(request);
	}
}
