import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { _routerOptions } from '@app/app.config';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { ToolsService } from '../services/tools.service';

@Injectable()
export class InitialValuesGuard implements Resolve<any> {
	constructor(private api: ApiService, private tools: ToolsService) {}
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
		try {
			const _existingData = this.tools.routeContextState.value || {};
			const _currentRouterOptions = _routerOptions.find(
				(el) => el.route === route.routeConfig.path || el.routeUrl === state.url,
			);
			const _routeData = _currentRouterOptions.routeData;
			const _toLoad = (_routeData && _routeData.filter((key) => !_existingData[key])) || [];

			if (!_routeData || _toLoad.length === 0) {
				const _data = { action: '_data', response: { data: _existingData } };
				this.tools.apiState.next(_data);
				return of(_data);
			} else {
				return this.api.collectionsListItems(_toLoad).pipe(
					map((_: any) => {
						const _data = {
							action: '_data',
							response: { data: Object.assign({}, _existingData, _.response.data) },
						};
						this.tools.apiState.next(_data);
						return _data;
					}),
				);
			}
		} catch (e) {
			console.error(e);
			return null;
		}
	}
}
