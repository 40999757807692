import { Component, OnDestroy } from '@angular/core';
import { ClientService } from '@app/shared/services/client.service';
import { FormService } from '@app/shared/services/forms.service';
import { ToolsService } from '@app/shared/services/tools.service';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	providers: [FormService],
})
export class LoginComponent implements OnDestroy {
	constructor(public fs: FormService, public tools: ToolsService, private clientService: ClientService) {
		this.fs.init('auth');

		this.tools.apiStates('user').pipe(untilDestroyed(this)).subscribe();
	}

	login = () => {
		if (this.tools.contextLoading.value) return;
		this.tools.contextLoading.next(true);
		this.clientService.login();
	};

	ngOnDestroy() {}
}
