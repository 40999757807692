import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormFieldVisibleDirective } from '@app/shared/directives/editor-field-visible.directive';
import { ClientService } from '@app/shared/services/client.service';
import { FormService } from '@app/shared/services/forms.service';
import { ToolsService } from '@app/shared/services/tools.service';
import { UserService } from '@app/shared/services/user.service';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
	selector: 'lokalise-info',
	templateUrl: './lokalise-info.component.html',
	providers: [
		FormService,
		DialogService,
		TitleCasePipe,
	],
})
export class LokaliseInfoComponent implements OnInit {
	constructor(
		public fs: FormService,
		public tools: ToolsService,
		public client: ClientService,
		public titleCase: TitleCasePipe,
		public user: UserService,
	) {
		fs.init('editor');
	}

	ngOnInit(): void {}
}
