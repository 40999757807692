import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ToolsService } from '@app/shared/services/tools.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ClientService } from '@app/shared/services/client.service';
import { AddArticleComponent } from './add-article/add-article.component';
import { DialogService } from 'primeng/dynamicdialog';
import { IArticleInfo, IUserInfo } from '@app/shared/app.interface';
import { mergeMap } from 'rxjs/operators';

@Component({
	selector: 'news-editor',
	templateUrl: './news.component.html',
	providers: [DialogService],
})
export class NewsFormComponent implements OnDestroy {
	news: IArticleInfo[] = [];
	reorderIsActive = false;
	clonedArticles: { [s: string]: IArticleInfo } = {};
	users: IUserInfo[] = [];

	constructor(
		public tools: ToolsService,
		private client: ClientService,
		public dialogService: DialogService,
		private _cdr: ChangeDetectorRef,
	) {
		tools
			.apiStates('_data')
			.pipe(
				untilDestroyed(this),
				mergeMap((_data: any) => {
					this.users = _data.users;
					return tools.apiStates('misc');
				}),
			)
			.subscribe((misc) => {
				this.news = (
					misc.map((item) => {
						return Object.assign({}, item, {
							author: this.users.find((_user) => {
								return _user._id === item._author;
							}),
						});
					}) || []
				).sort((a, b) => {
					return a.order - b.order;
				});
			});
	}

	toggleAddNewContent() {
		const ref = this.dialogService.open(AddArticleComponent, {
			header: 'Add New Article',
			width: '680px',
			styleClass: 'addNewArticleDialog',
			data: {
				news: this.news,
			},
		});

		ref.onClose.subscribe((_) => {
			if (_) {
				this.client.addNewMisc(_);
			}
		});
	}

	onRowDeleteInit(article: IArticleInfo) {
		if (confirm('Are you sure to delete article #' + article.order)) {
			this.client.deleteMisc(article);
		}
	}

	onRowEditInit(article: IArticleInfo) {
		this.clonedArticles[article._id] = { ...article };
	}

	onRowEditSave(article: IArticleInfo) {
		this.client.updateMisc(article);
	}

	onRowEditCancel(article: IArticleInfo, index) {
		this.news[index] = this.clonedArticles[article._id];
		delete this.clonedArticles[article._id];
	}

	rowReorder(e) {
		this.news = this.news.map((article, index) => ((article.order = (index + 1).toString()), article));
		this.client.reorderMisc(this.news.map((article) => ({ _id: article._id, order: article.order })));
	}

	ngOnDestroy() {}
}
