import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { ClientService } from '../shared/services/client.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class RestErrorInterceptor implements HttpInterceptor {
	constructor(private clientService: ClientService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
		// add cache for error request to prevent their call for second time + show message that such a request was blocked!

		return next.handle(request).pipe(
			catchError((err) => {
				if (err.status === 401 || err.status === 403) {
					this.clientService.logout();
				}
				if (err.status == 502 || err.status == 0) {
					this.clientService.serverLost();
				}

				if (err.error && err.error.data) console.error(err.error && err.error.data);

				const error = (err.error && err.error.messageCode && err.error) || err.statusText || err;
				return throwError(error);
			}),
		);
	}
}
