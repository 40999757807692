import { Component, OnInit } from '@angular/core';
import { FormService } from '@app/shared/services/forms.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ContentOptionsComponent } from '../content-options.component';
import { FormBuilder } from '@angular/forms';
import { ToastService } from '@app/shared/services/toast.service';

@Component({
	selector: 'local-options',
	templateUrl: './local-options.component.html',
	providers: [FormService],
})
export class LocalOptionsComponent extends ContentOptionsComponent implements OnInit {
	constructor(
		public fs: FormService,
		public ref: DynamicDialogRef,
		public fb: FormBuilder,
		public message: ToastService,
	) {
		super(fb, fs, message);
		fs.init('editor');
	}

	ngOnInit(): void {}
}
