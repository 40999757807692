import { Injectable } from '@angular/core';
import { IUserInfo } from '../app.interface';
import { Nullable, _routerOptions, _db, permission, projectSecuredRoutes, userRoles } from '@app/app.config';
import * as ls from 'localStorage';
import { ToolsService } from './tools.service';

@Injectable()
export class UserService {
	private _userInfo: Nullable<IUserInfo> = null;

	constructor(private tools: ToolsService) {}

	setUserInfo(val: IUserInfo) {
		val = val
			? Object.keys(val).reduce((acc, key) => {
					if (!!val[key]) acc[key] = val[key];
					return acc;
			  }, {})
			: val;
		this._userInfo = val;
	}

	get userInfo(): Nullable<IUserInfo> {
		return this._userInfo;
	}

	get initialized(): boolean {
		return !!this.userInfo;
	}

	get authorized(): boolean {
		return !!this.token;
	}

	get token(): string {
		return ls.getItem('access-token');
	}

	get securedRoutesDBCollsList() {
		return [...new Set([...Object.keys(_db), ...projectSecuredRoutes])];
	}

	hasRole(role: userRoles) {
		return this.userInfo.role === role;
	}

	toRead(route = this.tools.routerState): boolean {
		return this.checkPermission(route, permission.read);
	}

	toUpdate(route = this.tools.routerState): boolean {
		return this.checkPermission(route, permission.update);
	}

	toCreate(route = this.tools.routerState): boolean {
		return this.checkPermission(route, permission.create);
	}

	toDelete(route = this.tools.routerState): boolean {
		return this.checkPermission(route, permission.delete);
	}

	toApprove(route = this.tools.routerState): boolean {
		return this.checkPermission(route, permission.approve);
	}

	discardView(discardView: permission[]) {
		const _permissions = this.userInfo.permissions[this.tools.routerConfig.dbColl];
		if (discardView.every((item) => _permissions.includes(item))) return false;
	}

	attachView(attachView: permission[]) {
		const _permissions = this.userInfo.permissions[this.tools.routerConfig.dbColl];
		if (attachView.every((item) => _permissions.includes(item) || _permissions.includes(permission.all)))
			return true;
	}

	editorAttachView(attachView: permission[]) {
		const _permissions = this.userInfo.permissions['editor'];
		if (attachView.every((item) => _permissions.includes(item) || _permissions.includes(permission.all)))
			return true;
	}

	disableView(disableView: permission[]) {
		const _permissions = this.userInfo.permissions[this.tools.routerConfig.dbColl];
		if (disableView.every((item) => _permissions.includes(item))) return true;
	}

	private checkPermission(route: string, perm: permission): boolean {
		const _routeItem = _routerOptions.find((item) => item.route == route);
		return (
			(this.userInfo.permissions[route] &&
				(this.userInfo.permissions[route].indexOf(perm) !== -1 ||
					this.userInfo.permissions[route].indexOf(permission.all) !== -1)) ||
			(_routeItem &&
				this.userInfo.permissions[_routeItem.dbColl] &&
				(this.userInfo.permissions[_routeItem.dbColl].indexOf(perm) !== -1 ||
					this.userInfo.permissions[_routeItem.dbColl].indexOf(permission.all) !== -1))
		);
	}
}
