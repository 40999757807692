import { Component } from '@angular/core';
import { ToolsService } from '@app/shared/services/tools.service';
import { ClientService } from '@app/shared/services/client.service';

@Component({
	selector: 'file-editor',
	templateUrl: './files.component.html',
})
export class FilesFormComponent {
	files = [];
	origin = window.location.origin;

	constructor(public tools: ToolsService, private client: ClientService) {
		tools.apiStates(['misc', '_data']).subscribe((_) => {
			this.files =
				_.misc.map((item) =>
					Object.assign({}, item, { author: _._data.users.find((_user) => _user._id == item.author) }),
				) || [];
		});
	}

	uploadHandler(e) {
		this.tools.contextLoading.next(true);
		this.client.addNewMisc(e.files[0]);
	}

	onRowDeleteInit(e) {
		this.client.deleteMisc(e);
	}

	onRowUpdateInit(e, fileName) {
		this.client.updateMiscFile(Object.assign({}, e, { name: fileName }));
	}
}
