import { Component, OnInit } from '@angular/core';
import { FormService } from '@app/shared/services/forms.service';
import { ClientService } from '@app/shared/services/client.service';
import { ToolsService } from '@app/shared/services/tools.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'editor-content-finder',
	templateUrl: './content-finder.component.html',
	providers: [FormService],
})
export class ContentFinderComponent implements OnInit {
	searchResult;

	private _fieldsList: any[];

	constructor(
		public fs: FormService,
		private client: ClientService,
		private tools: ToolsService,
		private ref: DynamicDialogRef,
	) {
		fs.init('search');
		tools.apiStates('list').subscribe((_) => (this._fieldsList = _));
		tools.apiStates('search').subscribe((_) => (this.searchResult = _));
	}

	ngOnInit() {}

	onSelect(item_id) {
		this.tools.resetApiState('search');
		this.fs.getField('query').reset();
		this.ref.close(this._fieldsList.find((el) => el._id == item_id));
	}
}
