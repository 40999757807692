import { Component, OnInit } from '@angular/core';

import { MenuItem } from 'primeng/api';
import { ClientService } from '@app/shared/services/client.service';
import { UserService } from '@app/shared/services/user.service';
import { IUserInfo } from '@app/shared/app.interface';
import { _routerOptions } from '@app/app.config';
import { ToolsService } from '@app/shared/services/tools.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
	user: IUserInfo;
	items: MenuItem[] = [];

	constructor(
		private clientService: ClientService,
		public userService: UserService,
		private tools: ToolsService,
	) {
		this.user = userService.userInfo;
		if (this.user) {
			const _collections = Object.keys(this.user.permissions);
			const _menu = _routerOptions
				.filter((el) => el.menuItem)
				.reduce((acc, item, idx) => {
					if (_collections.indexOf(item.dbColl) !== -1 && userService.toRead(item.route)) {
						const _indx = item.parentLabel || '__' + idx;
						acc[_indx] = acc[_indx] || [];
						acc[_indx].push({
							label: item.label,
							routerLink: [item.route],
							parentLabel: _indx,
							command: (event) => {
								const _menuBar = event.originalEvent.path.find((el) =>
									(el.className || '').includes('ui-menubar-root-list'),
								);
								const _subMenu = [..._menuBar.children].filter((el) =>
									el.classList.contains('ui-menu-parent'),
								);
								const _isSubMenuChild = event.item.parentLabel;
								if (_isSubMenuChild) {
									_subMenu.forEach((_) => _.firstElementChild.classList.remove('ui-state-active'));
									_subMenu.forEach((_) => {
										if (_.firstElementChild.innerText === event.item.parentLabel) {
											_.firstElementChild.classList.add('ui-state-active');
										}
									});
								}
							},
						});
					}
					return acc;
				}, {});

			this.items = Object.keys(_menu).map((key) =>
				key.indexOf('__') === 0
					? _menu[key][0]
					: {
							label: key,
							items: _menu[key],
							styleClass: _menu[key].some((item) => item.routerLink[0] === tools.routerState)
								? 'ui-state-active'
								: '',
					  },
			);
		}
	}

	ngOnInit() {}

	logout() {
		this.clientService.logout();
	}
}
