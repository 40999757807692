import { Component, OnInit } from '@angular/core';
import { ToolsService } from '../shared/services/tools.service';
import { ToastService } from '../shared/services/toast.service';

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
})
export class MainComponent implements OnInit {
	constructor(public tools: ToolsService, public toast: ToastService) {}

	ngOnInit() {}
}
