import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	template: `
		<div>
			<h1>502</h1>
			<span>Server lost, pls try to reconnect</span>
			<h3>or</h3>
			<span>contact administrator</span>
		</div>
		<a href="javascript:window.location.href='/'">Log In</a>
	`,
	styles: [
		`
			body {
				font-family: monospace;
			}
			div {
				display: flex;
				flex-direction: column;
				align-items: center;
				height: 90%;
				justify-content: center;
			}
			h1 {
				font-size: 90px;
				margin: 30px;
			}
			span {
				font-size: 20px;
			}
			h3 {
				font-size: 20px;
			}
			a {
				margin: 0 auto;
				display: table;
				font-size: 14px;
			}
		`,
	],
	encapsulation: ViewEncapsulation.None,
})
export class ServerLostComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
