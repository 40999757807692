import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { HTTP_TIMEOUT, HTTP_LOKALISE_TIMEOUT } from '../app.config';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
	constructor(
		@Inject(HTTP_TIMEOUT) protected defaultTimeout: number,
		@Inject(HTTP_LOKALISE_TIMEOUT) protected lokaliseTimeout: number,
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const timeoutValue =
			Number(req.headers.get('timeout')) || req.url.includes('lokalise')
				? this.lokaliseTimeout
				: this.defaultTimeout;

		return next.handle(req).pipe(timeout(timeoutValue));
	}
}
