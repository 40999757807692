import { Directive, Input, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { NgControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Directive({
	selector: '[inputPrefix]',
})
export class InputPrefixDirective implements OnDestroy, AfterViewInit {
	@Input() inputPrefix: string;
	target: HTMLInputElement;
	evenListener$: Subscription;

	constructor(private NgC: NgControl, private elem: ElementRef) {}

	getCaretPosition(ctrl) {
		var iCaretPos = 0;
		var _document: any = document;

		if (_document.selection) {
			ctrl.focus();
			var oSel = _document.selection.createRange();
			oSel.moveStart('character', -ctrl.value.length);
			iCaretPos = oSel.text.length;
		} else if (ctrl.selectionStart || ctrl.selectionStart == '0') iCaretPos = ctrl.selectionDirection == 'backward' ? ctrl.selectionStart : ctrl.selectionEnd;
		return iCaretPos;
	}

	ngAfterViewInit() {
		this.NgC.control.parent
			.get('prefix')
			.valueChanges.pipe(untilDestroyed(this))
			.subscribe((_) => {
				const _oldPrefix = this.inputPrefix;
				this.inputPrefix = _ ? _.key : '';
				const _target = this.elem.nativeElement;
				_target.value = (_ ? _.key : '') + _target.value.replace(_oldPrefix, '');
			});

		console.log(this.NgC);
		this.evenListener$ = fromEvent(this.elem.nativeElement, 'input').subscribe((_changes: any) => {
			if (!this.inputPrefix) return;
			const _target = this.elem.nativeElement;
			const _caretPos = this.getCaretPosition(_target);
			let _charOnPrefix = false; // char written into prefix text
			if (_caretPos < this.inputPrefix.length) {
				_target.setSelectionRange(this.inputPrefix.length, this.inputPrefix.length);
				_charOnPrefix = true;
			}

			let _val = (typeof _changes == 'string' ? _changes : _target.value).split('');
			if (_charOnPrefix) {
				_val.splice(_caretPos - 1, 1);
				_val.push(_changes.data || '');
			}
			_val = _val.join('').replace(new RegExp(`(.+?${this.inputPrefix})+`, 'g'), this.inputPrefix);

			if (_val.indexOf(this.inputPrefix) == 0) {
				if (_val == _target.value) return;
				_target.value = _val;
			} else if (typeof _changes == 'string' && _changes != null && _changes != this.inputPrefix.slice(0, -1)) {
				_target.value = this.inputPrefix + _changes;
			} else {
				_target.value = this.inputPrefix;
			}
		});
	}

	ngOnDestroy() {
		if (this.evenListener$) this.evenListener$.unsubscribe();
	}
}
