import { Component } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FormService } from '@app/shared/services/forms.service';

@Component({
	selector: 'add-article',
	templateUrl: './add-article.component.html',
	providers: [FormService],
})
export class AddArticleComponent {
	constructor(public fs: FormService, private ref: DynamicDialogRef, private config: DynamicDialogConfig) {
		fs.init('article');
		fs.getField('order').patchValue(this.config.data.news.length + 1);
	}

	onSave() {
		if (this.fs.formValid) {
			this.ref.close(this.fs.form.value);
		}
	}

	onCancel() {
		this.ref.close(null);
	}

	onSelectFile(e) {
		this.fs.form.get('file').patchValue(e.currentFiles[0]);
	}

	onRemoveFile(e) {
		this.fs.form.get('file').patchValue(null);
	}
}
