import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
	providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
	constructor(private userService: UserService, private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		if (this.userService.toRead(route.routeConfig.path) == false) {
			this.router.navigate(['editor']);
			return false;
		}
		return true;
	}
}
