import { Component, OnInit } from '@angular/core';
import { _routerOptions } from '@app/app.config';
import { ToolsService } from '@app/shared/services/tools.service';

@Component({
	selector: 'app-editor',
	templateUrl: './editor.component.html',
})
export class EditorComponent implements OnInit {
	constructor(public tools: ToolsService) {}

	ngOnInit() {}

	get filterIsVisiable(): boolean {
		const _filterItems = this.tools.routeContextState.value;
		return (
			_routerOptions.some((el) => el.route === this.tools.routerState && !!el.filters) &&
			Object.keys(_filterItems).reduce((acc, key) => acc * _filterItems[key].length, 1) !== 1
		);
	}
}
