import { Component } from '@angular/core';
import { ToolsService } from './shared/services/tools.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
})
export class AppComponent {
	constructor(public tools: ToolsService) {}
}
