import { Component, OnDestroy } from '@angular/core';
import { FormService } from '@app/shared/services/forms.service';
import { _searchableFilters, _formsFieldToDBMap } from '@app/app.config';
import { ToolsService } from '@app/shared/services/tools.service';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
	selector: 'app-filter',
	templateUrl: './filter.component.html',
	providers: [FormService],
})
export class FilterComponent implements OnDestroy {
	filtersData = {};

	constructor(public fs: FormService, private tools: ToolsService) {
		fs.init('filter');
		tools.routeContextState.pipe(untilDestroyed(this)).subscribe((_items) => {
			if (!_items) return;
			this.filtersData = _items;
		});
	}

	_isExtended = (_field: string) => {
		return _searchableFilters.includes(_field);
	};

	filterVisible = (_field: string) =>
		this.fs.showFieldIfFormAllows(_field) && (this.filtersData[_formsFieldToDBMap[_field]] || []).filter((_) => _._id != null).length > 1;

	ngOnDestroy() {}
}
