import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'string-builder',
	template: ``,
	styles: [],
})
export class StringBuilderComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
