import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccordionModule } from 'primeng/accordion';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TreeModule } from 'primeng/tree';

@NgModule({
	exports: [
		AccordionModule,
		BlockUIModule,
		ButtonModule,
		ChartModule,
		CheckboxModule,
		DropdownModule,
		DynamicDialogModule,
		EditorModule,
		InputSwitchModule,
		InputTextareaModule,
		InputTextModule,
		ListboxModule,
		MenubarModule,
		MessageModule,
		MultiSelectModule,
		CardModule,
		PanelModule,
		ProgressSpinnerModule,
		RadioButtonModule,
		RouterModule,
		SelectButtonModule,
		SplitButtonModule,
		TableModule,
		ToastModule,
		ToggleButtonModule,
		FileUploadModule,
		CalendarModule,
		TreeModule,
	],
})
export class PrimengModule {}
